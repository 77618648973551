import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { PageContext } from '@/contexts/page'
import { generatePageMap, getPageWithFallback, locales } from '@/utils/routing'

import * as styles from './language-switch.css'

export default function LanguageSwitch(props) {
  const {
    pageContext: { pageId, locale: activeLocale },
  } = useContext(PageContext)

  const result = useStaticQuery(graphql`
    query LanguageSwitchComponent {
      allSitePage {
        nodes {
          path
          pageContext
        }
      }
    }
  `)

  // Generate a language based map of sub pages relating to the current content
  const pageMap = generatePageMap({
    pages: result.allSitePage.nodes,
    activePageId: pageId,
  })

  // Array representing the language switcher menu
  const langsMenu = locales
    .map((locale) => {
      const page = getPageWithFallback({ pageMap, locale })
      if (!page) {
        return null
      }
      return {
        locale,
        page,
      }
    })
    .filter(Boolean)

  if (!langsMenu.length) {
    return null
  }

  return (
    <div className={styles.List} {...props}>
      {langsMenu.map(({ page, locale }) => (
        <div
          className={styles.ListItem}
          key={locale}
          hidden={locale === activeLocale}
        >
          {page && page.path && (
            <Link
              className={styles.SwitcherLink}
              to={page.path}
              aria-label={`Switch language to ${locale}`}
            >
              {locale}
            </Link>
          )}
        </div>
      ))}
    </div>
  )
}

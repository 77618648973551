import "src/styles/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4WX21IiMRCG73mKlNzIlriTnvN4s4JStVd74e4DjDAIKgzOAOJu8e7bQV2S7majVR4++k8mIf1Fi6au1+pPR6l+v5481k+7oFBJsNpdWUgXSgfBdnbV2XcKFoBCBXZ1iL9fQtxUC5tGhlIY48AEJYhYXVooICgrVEhQXqiIoLJQdKx7XB9B40JlBE3MY2QpDVf8gaccPSDSkLmbOHvfxFebzQs1Lp/H54cv27I5/7fjPfUF9wu/9ZXNg17Pzj968uDJP3nyoSf/7MlHnvzCk489+aUnn3jytSefevIrTz7z5F88+dyTbz7yJPrfTIuNk12C6eYjXGNT4Hl34aZQ3WQwiG4HNt0ihWEe3YJNXw0dxTfX1zbdIdXhcDiMbPpmaoP8Onfob6TT6dTxDrqoG2Tm08Hoo+5NpgfByMEoou4oGMHIGVejj85+rKqluiuX7dmFOrurHupK/fqOP/8sZ/WivFAtvtRvq2buzo/SCgNHbhqVlRKEyooISnkw41U5HwuFReV7L/pUjw0OmaH0RBKtrgzlxYK89INkYD3jCtZz7mCNSsrxg6wBRaPVV6UdiPbQCdLcoeiECGHoQGx0U6ldWpsnTXT2PvK+c/lxAYFzP+mVvPYXYe2NXNqaiVjx2hTzO0JvTDV/w7bCfK/iXu8O0/ER3gxPKf0tV0MgVQP2DkjVYDijoUgj/r5DLLDDXR46ngDTGXyHIRNKzX3OJy+FSuFOh7FQN+EXPVRC3Yz1KmCnhJF7qgE7JUoIw56YL2dokrWDn1mrA3YKhCSNfRJqwmoexR4BWoYtAqTtoOHRlXSU4eWwCZRu+Da0/BDDWjrEsGMKBKEDQOwAMCed7rY552SBIZ7x2J0k1KYrg9QMuO98W1STeanacVPhBVAuJ+p8MV/2X+eTNb7HaYJ/o/UOsnDVcVIesj6oQDKpvnWs4GpEGGhj7wuxiNW/1CUp5webhGLk7Sh1KpREqDdKQXlIy6NeOWWWU26R7SL7RTbMScdQy9BqRzNUNLTYMg11DS21ZMN0Q+DRN65xoo//IVznCCtG6/Tx1OuYzXcUj6ueT5m58vnUmagfWUCugsKY0UYawGiILw57CjjdSLvTnuqDdx3xPrCEJCvplJSIlvjzYb8AW7alJllOSu07+789aJqFfw8AAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/mdx/custom/carousel.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/mdx/custom/carousel.css.ts.vanilla.css\",\"source\":\"LnB2bWVtdDAgewogIHBhZGRpbmc6IDE1cHggMXJlbSAwOwp9Ci5wdm1lbXQxIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAgaGVpZ2h0OiAxMDAlOwogIHBvc2l0aW9uOiByZWxhdGl2ZTsKICBwYWRkaW5nOiAycmVtOwogIHRleHQtYWxpZ246IGNlbnRlcjsKICBjb2xvcjogdmFyKC0tb2Rqb2t4dyk7CiAgYmFja2dyb3VuZDogdmFyKC0tb2Rqb2t4MTIpOwogIG1hcmdpbjogMTVweCAwIDA7CiAgYm9yZGVyLXJhZGl1czogOHB4Owp9Ci5wdm1lbXQxOmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHRvcDogLTE1cHg7CiAgbGVmdDogLTEwcHg7CiAgd2lkdGg6IDk0cHg7CiAgaGVpZ2h0OiA5NHB4OwogIGJhY2tncm91bmQ6IHVybCgiZGF0YTppbWFnZS9zdmcreG1sLCUzY3N2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCA5NCA5NCclM2UlM2NwYXRoIGZpbGw9JyUyMzJGNURBQScgZD0nTTI2IDBoNjh2MTVIMjZ6Jy8lM2UlM2NwYXRoIGZpbGw9JyUyMzJDOTRFMicgZD0nTTAgOTRWMTVBMTUgMTUgMCAwIDEgMTUgMGg3OUwwIDk0WicvJTNlJTNjL3N2ZyUzZSIpOwp9Ci5wdm1lbXQyIHsKICBib3JkZXItcmFkaXVzOiA5OTk5cHg7CiAgd2lkdGg6IDg2cHg7CiAgaGVpZ2h0OiA4NnB4Owp9Ci5wdm1lbXQzIHsKICBwYWRkaW5nLXRvcDogMXJlbTsKfQoucHZtZW10NCB7CiAgZm9udC13ZWlnaHQ6IHZhcigtLW9kam9reDE5KTsKICBtYXJnaW4tdG9wOiAxcmVtOwp9Ci5wdm1lbXQ2IHsKICBmb250LXdlaWdodDogdmFyKC0tb2Rqb2t4MTgpOwp9Ci5wdm1lbXQ3IHsKICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7CiAgbWFyZ2luOiAxcmVtIDA7Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var __esModule = true;
export var box = 'pvmemt1';
export var company = 'pvmemt6';
export var image = 'pvmemt2';
export var jobTitle = 'pvmemt5';
export var link = 'pvmemt7';
export var name = 'pvmemt4';
export var quote = 'pvmemt3';
export var slide = 'pvmemt0';
import React from 'react'

import * as styles from './link.css'

import { Link as BaseLink } from '../../link'

export const Link = ({ theme = null, href, ...props }) => (
  <BaseLink
    className={styles.linkRecipe({ theme })}
    theme={theme}
    href={href}
    {...props}
  />
)

import React from 'react'

import {
  AdaptiveProperty,
  useAdaptiveProperty,
} from '@/hooks/use-adaptive-property'
import {
  alignByColumnRecipe,
  AlignByColumnRecipe,
} from '@/styles/recipies/positioning.css'

import * as styles from './box.css'

interface BoxProps extends AlignByColumnRecipe {
  children: React.ReactNode
  /** Set the minimum height of the section. The section will grow if the content exceeds the given height. */
  height?: keyof typeof styles.height
  theme?: AdaptiveProperty<styles.BoxRecipe['theme']>
}

/**
 * The box element is used to group various other elements. It can be styled in various way like dimension restrictions, adding backgrounds and applying color themes. This is a very common element you will use regulary.
 */
export const Box: React.FC<BoxProps> = ({
  theme,
  height,
  alignX = `center`,
  alignY = `top`,
  ...props
}) => {
  const adaptiveTheme = useAdaptiveProperty(theme)
  return (
    <div
      className={[
        styles.boxRecipe({ theme: adaptiveTheme }),
        styles.height[height],
        alignByColumnRecipe({ alignX, alignY }),
      ].join(` `)}
      {...props}
    />
  )
}

import React, { useMemo } from 'react'

import {
  alignByRowRecipe,
  AlignByRowRecipe,
} from '@/styles/recipies/positioning.css'
import { mediaQueries } from '@/styles/media-queries'

import { useBreakpoints } from '@/hooks/use-breakpoints'
import {
  useAdaptiveProperty,
  AdaptiveProperty,
} from '@/hooks/use-adaptive-property'

import { assignInlineVars } from '@vanilla-extract/dynamic'
import * as styles from './grid.css'

interface GridProps extends AlignByRowRecipe {
  children: React.ReactNode
  /** Select a custom template for the grid instead of using even column widths */
  template?: AdaptiveProperty<keyof typeof styles.template>
  /** Override the number of grid columns */
  columns?: AdaptiveProperty<string>
  /** Set the gap on all axis between the grids elements */
  gap?: AdaptiveProperty<keyof typeof styles.gap>
  /** Set the gap on the horizontal axis between the grids elements */
  gapX?: AdaptiveProperty<keyof typeof styles.gap>
  /** Set the gap on the vertical axis between the grids elements */
  gapY?: AdaptiveProperty<keyof typeof styles.gap>
  /** Reverse the order of grid items at a certain breakpoint */
  reverseAt?: keyof typeof mediaQueries
}

/**
 * Use a Grid element to align elements next to each other. Use the Box element to group other elements into columns.
 */
export const Grid: React.FC<GridProps> = ({
  children,
  template = `equal`,
  columns,
  gap = [`paragraphGap`, `gridGap`],
  gapX,
  gapY,
  alignX = `spaceBetween`,
  alignY = `top`,
  alignContentX = `left`,
  alignContentY = `top`,
  reverseAt,
  ...props
}) => {
  const breakpoints = useBreakpoints()
  // Reverse columns based on reverseAt
  const processedChildren = useMemo(() => {
    if (!reverseAt || !breakpoints[reverseAt]) {
      return children
    }
    return React.Children.toArray(children).reverse()
  }, [breakpoints, children, reverseAt])

  // By default display grid items in one row
  const defaultColumnCount = useMemo(
    () => React.Children.toArray(children).length.toString(),
    [children],
  )

  const columnCount = useAdaptiveProperty(columns || defaultColumnCount)

  const adaptiveGap = useAdaptiveProperty(gap)
  const adaptiveGapX = useAdaptiveProperty(gapX)
  const adaptiveGapY = useAdaptiveProperty(gapY)
  const adaptiveTemplate = useAdaptiveProperty(template)

  return (
    <div
      className={[
        styles.grid,
        styles.gridTheme,
        styles.gap[adaptiveGap],
        styles.gapX[adaptiveGapX],
        styles.gapY[adaptiveGapY],
        alignByRowRecipe({
          alignX,
          alignY,
          alignContentX,
          alignContentY,
        }),
        styles.template[adaptiveTemplate],
      ].join(` `)}
      style={assignInlineVars({
        [styles.gridColumnCountVar]: columnCount,
      })}
      {...props}
    >
      {processedChildren}
    </div>
  )
}

import React from 'react'

import {
  blockFontRecipe,
  BlockFontRecipe,
  inlineFontRecipe,
  InlineFontRecipe,
} from '@/styles/recipies/font.css'

import * as styles from './subline.css'

interface SublineProps extends BlockFontRecipe, InlineFontRecipe {
  children: React.ReactNode
}

export const Subline: React.FC<SublineProps> = ({
  textAlign,
  family = `regular`,
  color,
  size = `lg`,
  weight,
  decoration,
  transform = `uppercase`,
  ...props
}) => (
  <div
    className={[
      styles.subline,
      blockFontRecipe({ textAlign }),
      inlineFontRecipe({ family, color, size, weight, decoration, transform }),
    ].join(` `)}
    role="doc-subtitle"
    {...props}
  />
)

import React from 'react'
import { Image } from '@/components/image'

import * as styles from './people-wall.css'

export const PeopleWall = (props) => <div className={styles.wall} {...props} />
export const PeopleWallImage = (props) => (
  <Image
    className={styles.image}
    {...props}
    preset="people"
    theme="circle"
    objectPosition="center"
  />
)
export const PeopleWallPlaceholder = (props) => (
  <div className={styles.placeholder} {...props} />
)

import React from 'react'

import {
  blockFontRecipe,
  BlockFontRecipe,
  inlineFontRecipe,
  InlineFontRecipe,
} from '@/styles/recipies/font.css'
import {
  AdaptiveProperty,
  useAdaptiveProperty,
} from '@/hooks/use-adaptive-property'

import * as styles from './box-content.css'

interface BoxContentProps
  extends Omit<BlockFontRecipe, 'textAlign'>,
    InlineFontRecipe {
  children: React.ReactNode
  /** Add spacing between the box border and the content. Should be set to 0 when the content column should be touched. */
  padding?: keyof typeof styles.padding
  /** Align the text on the horizontal axis */
  textAlign?: AdaptiveProperty<BlockFontRecipe['textAlign']>
}

/**
 * This element ensures all content within is displayed in the main content column of the screen.
 */
export const BoxContent: React.FC<BoxContentProps> = ({
  padding,
  textAlign,
  family,
  color,
  size,
  weight,
  decoration,
  transform,
  ...props
}) => {
  const adaptiveTextAlign = useAdaptiveProperty(textAlign)
  return (
    <div
      className={[
        styles.boxContent,
        styles.padding[padding],
        blockFontRecipe({ textAlign: adaptiveTextAlign }),
        inlineFontRecipe({
          family,
          color,
          size,
          weight,
          decoration,
          transform,
        }),
      ].join(` `)}
      {...props}
    />
  )
}

import React from 'react'
import MDX from '@mdx-js/runtime'

import DefaultPageLayout from '@/components/default-page-layout'

import * as styles from './preview.css'

interface PreviewProps {
  value: string
}

export const Preview: React.FC<PreviewProps> = ({ value }) => (
  <div className={styles.wrapper}>
    <DefaultPageLayout>
      <MDX>{value}</MDX>
    </DefaultPageLayout>
  </div>
)

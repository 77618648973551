import * as React from 'react'

import { AssetPresetNode, ContentfulPageContext } from '@/types'
import { AssetPresetKeys } from 'media-config'

export interface ContextMediaMapInterface
  extends Map<AssetPresetKeys, Map<string, AssetPresetNode>> {}

export interface PageContextInterface {
  media: ContextMediaMapInterface
  pageContext: ContentfulPageContext
}

export const PageContext = React.createContext<PageContextInterface>({
  media: new Map(),
  pageContext: {},
})

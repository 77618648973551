import {
  alignByColumnRecipe,
  AlignByColumnRecipe,
} from '@/styles/recipies/positioning.css'
import React from 'react'

import * as styles from './section.css'

interface SectionProps extends AlignByColumnRecipe {
  children: React.ReactNode
  /** Set the minimum height of the section. The section will grow if the content exceeds the given height. */
  height?: keyof typeof styles.height
  className?: string
}

/**
 * The Section element is used to split up your page into several segments. You have a fine granular control over is sizing behaviour. This is often used to provide different coloring and background to certain parts of the page.
 */
export const Section: React.FC<SectionProps> = ({
  className,
  height,
  alignX = `center`,
  alignY = `center`,
  ...props
}) => {
  const classNames = [
    className,
    styles.section,
    styles.height[height],
    alignByColumnRecipe({ alignX, alignY }),
  ]

  return <section className={classNames.join(` `)} {...props} />
}

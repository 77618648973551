import {
  AdaptiveProperty,
  useAdaptiveProperty,
} from '@/hooks/use-adaptive-property'
import {
  blockFontRecipe,
  BlockFontRecipe,
  inlineFontRecipe,
  InlineFontRecipe,
} from '@/styles/recipies/font.css'
import React from 'react'

import * as styles from './paragraph.css'

interface ParagraphProps
  extends Omit<BlockFontRecipe, 'textAlign'>,
    InlineFontRecipe {
  children: React.ReactNode
  textAlign: AdaptiveProperty<BlockFontRecipe['textAlign']>
  /** Set the gap size to the previous content element */
  gap?: keyof typeof styles.gap
}

export const Paragraph: React.FC<ParagraphProps> = ({
  gap,
  textAlign,
  family,
  color,
  size,
  weight,
  decoration,
  transform,
  ...props
}) => {
  const adaptiveTextAlign = useAdaptiveProperty(textAlign)
  return (
    <p
      className={[
        styles.paragraph,
        styles.gap[gap],
        blockFontRecipe({ textAlign: adaptiveTextAlign }),
        inlineFontRecipe({
          family,
          color,
          size,
          weight,
          decoration,
          transform,
        }),
      ].join(` `)}
      {...props}
    />
  )
}

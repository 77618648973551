import { useMemo } from 'react'

import { useBreakpoints } from '@/hooks/use-breakpoints'

export type AdaptiveProperty<T> = T | T[]

export const useAdaptiveProperty = (value: AdaptiveProperty<any>): any => {
  const breakpoints = useBreakpoints()

  const valueList = useMemo(() => {
    const list = Array.isArray(value) ? value : [value]
    // console.log({ list, breakKeys: Object.keys(breakpoints) })

    Object.keys(breakpoints).forEach((key, index) => {
      // console.log({ key, index })
      if (!list[index]) {
        list[index] = list[index - 1]
        // console.log('setting', index, ' to ', list[index - 1])
      }
    })
    return list
  }, [value, breakpoints])

  const adaptiveValue = useMemo(() => {
    const latestActiveBreakpointIndex = Object.values(breakpoints).reduce(
      (latestActiveIndex, isMatching, index) => {
        if (isMatching) {
          return index
        }
        return latestActiveIndex
      },
      0,
    )
    // console.log({ latestActiveBreakpointIndex, valueList })
    return valueList[latestActiveBreakpointIndex]
  }, [breakpoints])

  // console.log('adaptive trigger', JSON.stringify(breakpoints), adaptiveValue)

  return adaptiveValue
}

import React from 'react'

import * as styles from './separator.css'

interface SeparatorProps {
  /** The color of the separator line */
  color?: keyof typeof styles.color
  /** Optional text displayed centered on top of the separator */
  text?: string
}

/**
 * This element ensures all content within is displayed in the main content column of the screen.
 */
export const Separator: React.FC<SeparatorProps> = ({
  color = `primary`,
  text,
  ...props
}) => (
  <div className={styles.wrapper} {...props}>
    <div className={[styles.line, styles.color[color]].join(` `)} />
    <div className={styles.textWrapper}>{text}</div>
  </div>
)

import React, { useCallback, useContext } from 'react'
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby'

import { smoothScrollTo } from '@/utils/smooth-scroll-to'
import { PageContext } from '@/contexts/page'
import * as styles from './link.css'

interface LinkBase extends styles.LinkRecipe {
  /** Option hash to attach to the link href */
  hash?: string
  /** Optional title. Should be set for a11y and seo reasons when link has non-text content. */
  title?: string
  /** Open linked page in new tag. Should only be used for edge-cases. See: https://css-tricks.com/use-target_blank/ */
  openInNewTab?: boolean
  children?: React.ReactChild | React.ReactChild[]
  className?: string
  [key: string]: any
}

interface InternalLink extends LinkBase {
  /** Id of an internal page to link to */
  id: string
}

interface HardCodedLink extends LinkBase {
  /** Slug of an internal page to link to. **Note:** use this for hard-coded pages **only** */
  to: string
}

interface ExternalLink extends LinkBase {
  /** URI of an external page to link to */
  href: string
}

export type LinkProps = InternalLink | HardCodedLink | ExternalLink

/**
 * Link either an internal or external page.
 *
 * Title will be automatically set for internal pages.
 *
 * @example
 * <Link id="pageIdFromContentful" />
 * @example
 * <Link id="pageIdFromContentful">Internal link with given title</Link>
 * @example
 * <Link to="/custom-feature">Internal link to hardcoded page</Link>
 * @example
 * <Link href="https://google.com">External link to Google</Link>
 * @example
 * <Link href="https://google.com" openInNewTab>External link to Google, opening in a new tab</Link>
 */
export const Link: React.FC<InternalLink | HardCodedLink | ExternalLink> = ({
  theme = null,
  openInNewTab = false,
  hash,
  children,
  title,
  ...props
}) => {
  const { pageContext } = useContext(PageContext)
  const allPages = useStaticQuery<Queries.LinkComponentQuery>(graphql`
    query LinkComponent {
      allSitePage {
        nodes {
          path
          pageContext
        }
      }
    }
  `)

  const linkProps: { [key: string]: unknown } = { ...props }
  delete linkProps.href
  delete linkProps.to
  delete linkProps.id

  if (openInNewTab) {
    linkProps.target = `_blank`
    linkProps.rel = `noopener`
  }

  const className = [styles.linkRecipe({ theme }), props.className].join(` `)

  // Smooth scroll when link scroll target is on same page
  const handleOnClick = useCallback((e) => {
    if (props.onClick) {
      props.onClick(e)
    }
    if (!e.target.href) {
      return
    }
    const targetUrl = new URL(e.target.href)
    if (
      targetUrl.host === window.location.host &&
      targetUrl.pathname === window.location.pathname &&
      targetUrl.hash
    ) {
      e.preventDefault()
      smoothScrollTo(targetUrl.hash)
      window.history.replaceState({}, ``, e.target.href)
    }
  }, [])

  // Link hard coded page when to is given
  if (`to` in props) {
    return (
      <GatsbyLink
        {...linkProps}
        className={className}
        activeClassName="active"
        to={[props.to, hash ? `#${hash}` : null].filter(Boolean).join(``)}
        onClick={handleOnClick}
      >
        {children || title}
      </GatsbyLink>
    )
  }

  // Link hard coded page when to is given
  if (`id` in props) {
    const locale = pageContext.locale || `en`
    const link = allPages.allSitePage.nodes.find(
      (linkTarget) =>
        linkTarget.pageContext.pageId === props.id &&
        linkTarget.pageContext.locale === locale,
    )
    if (!link) {
      throw new Error(`Unable to find link to page with id ${props.id}`)
    }
    return (
      <GatsbyLink
        {...linkProps}
        className={className}
        activeClassName="active"
        to={[link.path, hash ? `#${hash}` : null].filter(Boolean).join(``)}
        onClick={handleOnClick}
      >
        {children || title}
      </GatsbyLink>
    )
  }

  // Render a normal anchor when a href is given
  if (`href` in props) {
    return (
      <a {...linkProps} className={className} href={props.href} title={title}>
        {children || title}
      </a>
    )
  }

  throw new Error(`Unable to render Link:\n${JSON.stringify(props, null, 2)}`)
}

import React from 'react'

import { Link } from '@/components/link'

import { Trans } from '@lingui/macro'
import * as styles from './footer.css'

export function Footer() {
  return (
    <footer className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.addressWrapper}>
          <div className={styles.address}>
            SoniQ Services GmbH
            <br />
            <span className={styles.thinFont}>Münzstrasse 13</span>
            <br />
            <span className={styles.thinFont}>10178 Berlin Germany</span>
            <br />
            <Link theme="footer" href="mailto:info@soniqservices.de">
              info@soniqservices.de
            </Link>
            <br />
            <Link theme="footer" href="tel:+493057711600">
              (030) 57711600
            </Link>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 666 307"
            className={styles.addressBackgroundImage}
          >
            <path
              fill="#2C94E2"
              d="M0 307 287 24a81 81 0 0 1 55-23 74 74 0 0 1 53 21l271 285H0Z"
            />
          </svg>
        </div>
        <nav className={styles.menu}>
          <Link theme="footer" id="6Rfk2Hlf44SASEIcYsgq8e">
            <Trans>Data Policy</Trans>
          </Link>
          <Link theme="footer" id="2HH7URQjY4cqy4cMe222EA">
            <Trans>Site Notice</Trans>
          </Link>
          <Link
            theme="footer"
            href="https://soniqservices.jobs.personio.de/"
            openInNewTab
          >
            <Trans>Jobs</Trans>
          </Link>
        </nav>
        <div className={styles.social}>
          <Link
            theme="footer"
            href="https://www.linkedin.com/company/soniq-services/"
            target="_blank"
            referrerPolicy="no-referrer"
          >
            <img
              src="/linked-in.png"
              alt="LinkedIn"
              style={{ display: `inline` }}
              width="61"
              height="51"
            />
          </Link>
        </div>
        <div className={styles.copyright}>
          <br />© SoniQ Services GmbH. <Trans>All rights reserved</Trans>.
        </div>
      </div>
    </footer>
  )
}

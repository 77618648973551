import {
  AdaptiveProperty,
  useAdaptiveProperty,
} from '@/hooks/use-adaptive-property'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import React from 'react'

import * as styles from './background-triangle.css'

interface BackgroundTriangleProps extends styles.BackgroundTriangleRecipe {
  /** Move the triangle on the horizontal axis. Use a value with a unit of % or px */
  modifierX?: AdaptiveProperty<string>
  /** Move the triangle on the horizontal axis. Use a value with a unit of % or px */
  modifierY?: AdaptiveProperty<string>
  /** If set to false, triangles will not exceed their Section */
  overflow?: boolean
}

export const BackgroundTriangle: React.FC<BackgroundTriangleProps> = ({
  modifierX = `0%`,
  modifierY = `0%`,
  color,
  direction = `right`,
  overflow = true,
}) => {
  const adaptiveModifierX = useAdaptiveProperty(modifierX)
  const adaptiveModifierY = useAdaptiveProperty(modifierY)
  return (
    <div
      className={styles.backgroundTriangleRecipe({ direction, color })}
      style={{
        ...assignInlineVars({
          [styles.backgroundTriangleModifierX]: adaptiveModifierX,
          [styles.backgroundTriangleModifierY]: adaptiveModifierY,
        }),
        overflow: overflow && direction !== `down` ? `visible` : `hidden`,
      }}
    >
      {direction === `down` ? (
        <svg
          className={styles.svgRecipe({ direction })}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 1780 1780"
        >
          <g clipPath="url(#triangle-down)">
            <path
              fill="currentColor"
              d="M1035 1719c-80 80-210 80-290 0L-5 969V-27h1790v996l-750 750Z"
            />
          </g>
          <defs>
            <clipPath id="triangle-down">
              <path fill="#fff" d="M0 0h1780v1780H0z" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          className={styles.svgRecipe({ direction })}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 805 1780"
        >
          <g clipPath="url(#triangle-right)">
            <path
              fill="currentColor"
              d="M744 745c80 80 80 210 0 290L-6 1785l-895-895L-6-5l750 750Z"
            />
          </g>
          <defs>
            <clipPath id="triangle-right">
              <path fill="#fff" d="M0 0h805v1780H0z" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  )
}

import {
  AdaptiveProperty,
  useAdaptiveProperty,
} from '@/hooks/use-adaptive-property'
import React from 'react'

import * as styles from './gap.css'

interface GapProps {
  /** The height of the gap */
  size: AdaptiveProperty<keyof typeof styles.size>
  className?: string
}

/**
 * This element ensures all content within is displayed in the main content column of the screen.
 */
export const Gap: React.FC<GapProps> = ({
  size = `xl`,
  className,
  ...props
}) => {
  const adaptiveSize = useAdaptiveProperty(size)
  return (
    <div
      className={[className, styles.size[adaptiveSize]].join(` `)}
      {...props}
    />
  )
}

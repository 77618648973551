import React, { useCallback, useEffect, useState } from 'react'

import type * as Monaco from 'monaco-editor'
import type * as LoaderJS from 'loader.js'

const EditorInterface = React.lazy(() => import(`@/editor/interface`))

declare global {
  interface Window {
    require: LoaderJS
    monaco?: typeof Monaco
  }
}

const appendScript = (src, onLoadCb = null) => {
  const script = document.createElement(`script`)
  script.src = src
  if (onLoadCb) {
    script.onload = onLoadCb
  }
  document.body.appendChild(script)
}

const EditorPage = () => {
  const [monaco, setMonaco] = useState(null)

  const detectGlobalMonaco = useCallback(() => {
    if (!monaco) {
      if (!window.monaco) {
        window.setTimeout(detectGlobalMonaco, 100)
        return
      }
      setMonaco(window.monaco)
    }
  }, [monaco, setMonaco])

  const [monacoBooted, setMonacoBooted] = useState(false)
  useEffect(() => {
    if (window.monaco) {
      detectGlobalMonaco()
      return
    }

    if (!monacoBooted) {
      window.require = {
        paths: { vs: `https://unpkg.com/monaco-editor@latest/min/vs` },
      }
      appendScript(
        `https://unpkg.com/monaco-editor@latest/min/vs/loader.js`,
        () => {
          appendScript(
            `https://unpkg.com/monaco-editor@latest/min/vs/editor/editor.main.nls.js`,
          )
          appendScript(
            `https://unpkg.com/monaco-editor@latest/min/vs/editor/editor.main.js`,
            detectGlobalMonaco,
          )
        },
      )
      setMonacoBooted(true)
    }
  }, [monacoBooted, setMonacoBooted])

  const isSSR = typeof window === `undefined`

  return !isSSR ? (
    <React.Suspense fallback={<div>Loading...</div>}>
      <EditorInterface monaco={monaco} />
    </React.Suspense>
  ) : null
}

export default EditorPage

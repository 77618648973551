import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  useDisclosureState,
  Disclosure,
  DisclosureContent,
  DisclosureStateReturn,
} from 'reakit/Disclosure'
import { assignInlineVars } from '@vanilla-extract/dynamic'

import { vars } from '@/styles/theme.css'
import { Image } from '@/components/image'
import { Icon } from '@/components/icon'

import * as styles from './accordion.css'
import { Video } from './video'

interface AccordionSlideProps {
  children: React.ReactNode
  /** Label of the slide toggle. */
  label: string
  /** ID of Contentful image displayed next to the content */
  imageId?: string
  /** Video displayed next to the content */
  videoFile?: string
  /* Defines if the slide is opened */
  visible?: boolean
  /* Passes the disclosure instance back to the parent */
  setDisclosure?: (disclosure: DisclosureStateReturn) => void
  /* Toggles the visibility via the parent to ensure only one slide keeps open */
  toggleVisibility?: () => void
  /* Media to be rendered */
  media: React.ReactNode
}

export const AccordionSlide: React.FC<AccordionSlideProps> = ({
  children,
  label,
  visible,
  setDisclosure,
  media,
  toggleVisibility,
}) => {
  const disclosure = useDisclosureState({ visible })

  useEffect(() => {
    setDisclosure(disclosure)
  }, [disclosure])

  const onToggleClick = useCallback<MouseEventHandler>((e) => {
    e.preventDefault()
    toggleVisibility()
  }, [])

  return (
    <>
      <Disclosure
        {...disclosure}
        onClick={onToggleClick}
        className={styles.toggle}
      >
        <Icon
          name="toggle"
          className={[
            styles.toggleIcon,
            disclosure.visible && styles.toggleIconOpen,
          ]
            .filter(Boolean)
            .join(` `)}
        />
        {label}
      </Disclosure>
      <DisclosureContent {...disclosure}>
        <div className={styles.content}>
          {children}
          <div className={styles.media}>{media}</div>
        </div>
      </DisclosureContent>
    </>
  )
}

interface AccordionProps {
  children: React.ReactNode
  toggleColor?: keyof typeof vars.color
}

type MediaAsset = { image: string; video: string }

const renderMediaAsset = (mediaAsset: MediaAsset) => {
  if (mediaAsset.image) {
    return (
      <Image
        key={mediaAsset.image}
        id={mediaAsset.image}
        preset="half"
        aspectRatio="square"
        fit="contain"
        objectPosition="top"
      />
    )
  }
  if (mediaAsset.video) {
    return (
      <Video
        key={mediaAsset.video}
        id={mediaAsset.video}
        preset="half"
        aspectRatio="square"
        autoPlay
        controls={false}
        pauseOnHover
      />
    )
  }
  return null
}

export const Accordion: React.FC<AccordionProps> = ({
  children,
  toggleColor = `black`,
}) => {
  const disclosures = useRef<{ [key: number]: DisclosureStateReturn }>({})
  const mediaFiles = useRef<MediaAsset[]>([])
  const [activeMedia, setActiveMedia] = useState(0)

  const onToggleVisibility = useCallback((slideIndex: number) => {
    const activeDisclosure = disclosures.current[slideIndex.toString()]

    if (!activeDisclosure) {
      return
    }

    // Invert visible state and open previous slide when closing
    const openSlide = !activeDisclosure.visible
      ? slideIndex
      : Math.max(0, slideIndex - 1)

    // Load new media
    setActiveMedia(openSlide)

    // Update visible state based on calculated openSlide
    Object.entries(disclosures.current).forEach(([key, disclosure]) => {
      disclosure.setVisible(parseInt(key, 10) === openSlide)
    })
  }, [])

  // Manipulate children for accordion behavior
  const slides = useMemo(
    () =>
      React.Children.map(children, (child: React.ReactElement, i) => {
        if (!React.isValidElement<AccordionSlideProps>(child)) {
          return null
        }

        // Extract media from slides
        let mediaFile = null
        if (child.props.imageId) {
          mediaFile = { image: child.props.imageId }
        }
        if (child.props.videoFile) {
          mediaFile = { video: child.props.videoFile }
        }
        mediaFiles.current[i] = mediaFile
        const visible = i === 0

        // Inject control props
        return React.cloneElement(child, {
          visible,
          media: renderMediaAsset(mediaFile),
          setDisclosure: (disclosure: DisclosureStateReturn) => {
            disclosures.current[i] = disclosure
          },
          toggleVisibility: () => onToggleVisibility(i),
        })
      }),
    [children],
  )

  const media = useMemo(
    () =>
      mediaFiles.current.map((mediaAsset, i) => {
        if (i !== activeMedia) {
          return null
        }
        return renderMediaAsset(mediaAsset)
      }),
    [activeMedia],
  )

  return (
    <div
      className={styles.accordion}
      style={assignInlineVars({
        [styles.toggleColorVar]: vars.color[toggleColor],
      })}
    >
      <div className={styles.mediaWrapper}>{media}</div>
      <div className={styles.slidesWrapper}>{slides}</div>
    </div>
  )
}

import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { getSegment } from '@consent-manager/integration-segment'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'

import { PageContextInterface } from '@/contexts/page'
import { messages as enMessages } from './src/locales/en/messages'
import { messages as deMessages } from './src/locales/de/messages'
import { smoothScrollTo } from './src/utils/smooth-scroll-to'

export const onInitialClientRender = () => {
  requestAnimationFrame(() => {
    smoothScrollTo(window.location.hash)
  })
}

/**
 * Called when the user changes routes, including on the initial load of the app
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
 */
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  const segment = getSegment()

  // This ensures plugins like react-helmet finished their work
  requestAnimationFrame(() => {
    if (segment && segment.page && typeof segment.page === `function`) {
      segment.page()
    }
  })
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const pageContext =
    props.pageContext as unknown as PageContextInterface['pageContext']
  i18n.activate(pageContext.locale || `en`)
  return element
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  i18n.load({
    en: enMessages,
    de: deMessages,
  })
  i18n.activate(`en`)
  return <I18nProvider i18n={i18n}>{element}</I18nProvider>
}

import React, { useEffect, useRef, useState } from 'react'

import type * as Monaco from 'monaco-editor'

import { useMedia } from 'react-use'
import * as styles from './editor.css'

import { useRegisterAutocomplete } from './autocompletion'

interface EditorProps {
  monaco: typeof Monaco
  value: string
  setValue: React.Dispatch<string>
}

const Editor: React.FC<EditorProps> = ({ monaco, value, setValue }) => {
  const editorRef = useRef(null)
  const [editorInstance, setEditorInstance] = useState(null)

  const prefersDarkMode = useMedia(`(prefers-color-scheme: dark)`)

  // Editor mounting
  const registerAutocomplete = useRegisterAutocomplete(window.monaco)

  useEffect(() => {
    if (monaco && !editorInstance) {
      const editor = monaco.editor.create(editorRef.current, {
        value,
        language: `markdown`,
        scrollBeyondLastLine: false,
        autoClosingBrackets: `never`,
        wordBasedSuggestions: false,
        theme: prefersDarkMode ? `vs-dark` : `vs`,
        // @todo replace with viewport size change listener for better performance
        automaticLayout: true,
        // inlineSuggest: false,
        // inlineHints: true,
        renderLineHighlight: `gutter`,
        unusualLineTerminators: `auto`,
        wordWrap: `on`,
        wrappingIndent: `same`,
      })
      registerAutocomplete()
      editor.onDidChangeModelContent(() => {
        setValue(editor.getValue())
      })
      setEditorInstance(editor)
    }
  }, [monaco, editorInstance, setEditorInstance])

  return <div ref={editorRef} className={styles.editor} />
}

export default Editor

import React from 'react'

import {
  blockFontRecipe,
  BlockFontRecipe,
  inlineFontRecipe,
  InlineFontRecipe,
} from '@/styles/recipies/font.css'
import {
  AdaptiveProperty,
  useAdaptiveProperty,
} from '@/hooks/use-adaptive-property'

import * as styles from './section-content.css'

type gaps = keyof typeof styles.gapTop

interface SectionContentProps extends BlockFontRecipe, InlineFontRecipe {
  children: React.ReactNode
  /** Set the vertical axis gap between content and section border */
  gap?: AdaptiveProperty<gaps>
  /** Set the top gap between content and section border */
  gapTop?: AdaptiveProperty<gaps>
  /** Set the bottom gap between content and section border */
  gapBottom?: AdaptiveProperty<gaps>
  /** Set a maximum width for this content area */
  width?: AdaptiveProperty<string>
}

/**
 * This element ensures all content within is displayed in the main content column of the screen.
 */
export const SectionContent: React.FC<SectionContentProps> = ({
  gap = `sectionGap`,
  gapTop,
  gapBottom,
  width,
  textAlign,
  family,
  color,
  size,
  weight,
  decoration,
  transform,
  ...props
}) => {
  const adaptiveWidth = useAdaptiveProperty(width)
  const adaptiveGap = useAdaptiveProperty(gap)
  const adaptiveGapTop = useAdaptiveProperty(gapTop)
  const adaptiveGapBottom = useAdaptiveProperty(gapBottom)
  return (
    <div
      className={[
        styles.sectionContent,
        styles.gapTop[adaptiveGapTop || adaptiveGap],
        styles.gapBottom[adaptiveGapBottom || adaptiveGap],
        blockFontRecipe({ textAlign }),
        inlineFontRecipe({
          family,
          color,
          size,
          weight,
          decoration,
          transform,
        }),
      ].join(` `)}
      style={{ maxWidth: adaptiveWidth }}
      {...props}
    />
  )
}

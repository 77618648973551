import React, { useContext, useMemo } from 'react'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import { PageContext } from '@/contexts/page'
import {
  AdaptiveProperty,
  useAdaptiveProperty,
} from '@/hooks/use-adaptive-property'
import {
  alignRecipe,
  AlignRecipe,
  fitRecipe,
  FitRecipe,
  borderRadiusRecipe,
  BorderRadiusRecipe,
} from '@/styles/recipies/media.css'
import {
  aspectRatioRecipe,
  AspectRatioRecipe,
  gatsbyImageAspectRatioFix,
} from '@/styles/recipies/dimensions.css'

import { assetPresets } from '../../media-config'

import * as styles from './image.css'

export interface BaseImageProps
  extends Partial<Omit<GatsbyImageProps, 'css'>>,
    Partial<
      Omit<
        React.ImgHTMLAttributes<HTMLImageElement>,
        'css' | 'onError' | 'onLoad' | 'width' | 'height'
      >
    >,
    AspectRatioRecipe,
    AlignRecipe,
    FitRecipe,
    BorderRadiusRecipe,
    styles.ThemeRecipe {
  /** Set a maximum width for this image */
  width?: AdaptiveProperty<string>
  /** Set a maximum height for this image */
  height?: AdaptiveProperty<string>
  /** Set a radius for this image */
  radius?: string
  /* Locale of file (temporary, will be automatically set by page language) */
  locale?: string
  /* class to attach to the actual image */
  classNameImg?: string
}

const useImageStyle = ({
  className,
  classNameImg,
  theme,
  aspectRatio,
  alignX,
  alignY,
  fit,
  width,
  height,
  borderRadius,
}: {
  className: BaseImageProps['className']
  classNameImg: BaseImageProps['classNameImg']
  theme: BaseImageProps['theme']
  aspectRatio: BaseImageProps['aspectRatio']
  alignX: BaseImageProps['alignX']
  alignY: BaseImageProps['alignY']
  fit: BaseImageProps['fit']
  width: BaseImageProps['width']
  height: BaseImageProps['height']
  borderRadius: BaseImageProps['borderRadius']
}) => {
  const adaptiveWidth = useAdaptiveProperty(width)
  const adaptiveHeight = useAdaptiveProperty(height)

  const classNameWrapper = useMemo(() => {
    const wrapperClasses = [
      className,
      styles.wrapper,
      styles.themeRecipe({ theme }),
      borderRadius ? borderRadiusRecipe({ borderRadius }) : ``,
    ]

    if (!height && aspectRatio) {
      wrapperClasses.push(
        aspectRatioRecipe({ aspectRatio }),
        gatsbyImageAspectRatioFix,
      )
    }

    return wrapperClasses.join(` `)
  }, [className, theme, borderRadius, height, aspectRatio])

  const classNameImage = [
    styles.image,
    classNameImg,
    alignRecipe({ alignX, alignY }),
    fitRecipe({ fit }),
  ]
    .filter(Boolean)
    .join(` `)

  return {
    classNameImage,
    classNameWrapper,
    adaptiveHeight,
    adaptiveWidth,
  }
}

const HTMLImage = ({
  src,
  alt,
  imgClassName,
  className,
  style,
  imgStyle = {},
  ...props
}) => (
  <span className={className} style={style}>
    <img
      className={imgClassName}
      src={src}
      alt={alt || ``}
      style={imgStyle}
      {...props}
    />
  </span>
)

interface InternalImageProps extends BaseImageProps {
  /** Id of image in Contentful */
  id?: string
  /** Relative path to file within code base of this project */
  file?: string
  /** Define in which size the image is being rendered on desktop size. Set this properly to ensure the page loads fast. */
  preset?: keyof typeof assetPresets
}

const useImage = ({ contentfulId, file, preset, locale }) => {
  const { media: mediaContextData } = useContext(PageContext)
  // Load images from context
  if (!contentfulId && !file) {
    throw new Error(
      `Unable to render image ${JSON.stringify({ file, contentfulId })}`,
    )
  }
  const contextData = mediaContextData.get(preset)
  const nodeId = contentfulId ? `${contentfulId}-${locale}` : file
  if (!contextData) {
    throw new Error(`Preset ${preset} is not avilable (Image: ${nodeId})`)
  }

  const node = contextData.get(nodeId)

  if (!node) {
    throw new Error(`Unable to locate image ${nodeId} in preset ${preset}`)
  }

  return node
}

const InternalImage: React.FC<InternalImageProps> = ({
  id,
  file,
  preset = `full`,
  alt = ``,
  // style props
  className,
  classNameImg,
  theme,
  aspectRatio,
  alignX,
  alignY,
  fit = `contain`,
  width,
  height,
  borderRadius,
  ...props
}) => {
  const {
    pageContext: { locale },
  } = useContext(PageContext)
  const node = useImage({ contentfulId: id, file, preset, locale })
  const { classNameImage, classNameWrapper, adaptiveHeight, adaptiveWidth } =
    useImageStyle({
      className,
      classNameImg,
      theme,
      aspectRatio,
      alignX,
      alignY,
      fit,
      width,
      height,
      borderRadius,
    })

  const { renderWidth, renderHeight } = useMemo(() => {
    let targetHeight =
      adaptiveHeight ??
      node.childImageSharp?.gatsbyImageData?.height ??
      node.childImageSharp?.original?.height ??
      null
    let targetWidth =
      adaptiveWidth ??
      node.childImageSharp?.gatsbyImageData?.width ??
      node.childImageSharp?.original?.width ??
      null

    // If we can, set height automatically
    if (!adaptiveHeight && adaptiveWidth && node.childImageSharp?.original) {
      const originalAspectRatio =
        node.childImageSharp.original.width /
        node.childImageSharp.original.height

      targetWidth = originalAspectRatio * parseInt(adaptiveHeight, 10)
    }

    if (typeof targetWidth === `number`) {
      targetWidth = `${Math.floor(targetWidth)}px`
    }

    if (typeof targetHeight === `number`) {
      targetHeight = `${Math.floor(targetHeight)}px`
    }

    return {
      renderWidth: targetWidth,
      renderHeight: targetHeight,
    }
  }, [adaptiveHeight, adaptiveWidth, node])

  // Compose further image props based on media context data
  const imageProps = {
    // The alt test should describe whats in the image: https://moz.com/learn/seo/alt-text
    alt: node.description || node.title || alt || ``,
    // The title is used as tooltip.
    // Will probably be removed as it comes with accessability problems:
    // https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Images_in_HTML#image_titles
    title: node.title ?? null,
  }

  const style = {
    ...(props.style || {}),
    width: `100%`,
    maxWidth: renderWidth,
    maxHeight: renderHeight,
  }

  // Render svgs as HTML img tag
  if (node.svg?.dataURI) {
    return (
      <HTMLImage
        className={classNameWrapper}
        imgClassName={classNameImage}
        src={node.svg.dataURI}
        style={style}
        {...imageProps}
        {...props}
      />
    )
  }

  // Get gatsby-plugin-image rendering data from Contentful asset and file nodes
  const gatsbyImageData =
    node.gatsbyImageData || node?.childImageSharp?.gatsbyImageData

  if (!gatsbyImageData) {
    // Render as normal image for editor preview
    const publicURL = node.publicURL || node.file?.url
    if (publicURL) {
      return (
        <HTMLImage
          className={classNameWrapper}
          imgClassName={classNameImage}
          src={publicURL}
          style={style}
          {...imageProps}
          {...props}
        />
      )
    }
    throw new Error(`No render data found in ${JSON.stringify(node, null, 2)}`)
  }

  if (renderWidth) {
    gatsbyImageData.width = parseInt(renderWidth, 10)
  }
  if (renderHeight) {
    gatsbyImageData.height = parseInt(renderHeight, 10)
  }

  return (
    <GatsbyImage
      image={gatsbyImageData}
      imgClassName={classNameImage}
      className={classNameWrapper}
      objectFit={fit}
      as="span"
      style={style}
      {...imageProps}
      {...props}
    />
  )
}

interface ExternalImageProps extends BaseImageProps {
  /** Valid URI to the image. Preferably using https:// */
  src: string
}

const ExternalImage: React.FC<ExternalImageProps> = ({
  src,
  alt = ``,
  // style props
  className,
  classNameImg,
  theme,
  aspectRatio,
  alignX,
  alignY,
  fit,
  width,
  height,
  borderRadius,
  ...props
}) => {
  const { classNameImage, classNameWrapper } = useImageStyle({
    className,
    classNameImg,
    theme,
    aspectRatio,
    alignX,
    alignY,
    fit,
    width,
    height,
    borderRadius,
  })
  return (
    <HTMLImage
      className={classNameWrapper}
      imgClassName={classNameImage}
      src={src}
      alt={alt}
      style={{}}
      {...props}
    />
  )
}

export interface ImageProps
  extends BaseImageProps,
    Partial<InternalImageProps>,
    Partial<ExternalImageProps> {}

export const Image: React.FC<ImageProps> = ({ src, ...props }) => {
  if (src) {
    return <ExternalImage src={src} {...props} />
  }

  return <InternalImage {...props} />
}

/*eslint-disable*/ module.exports = {
  messages: {
    '9:00 – 18:00 CET': '9:00 – 18:00 MEZ',
    'All rights reserved': 'Alle Rechte vorbehalten',
    Contact: 'Kontakt',
    'Data Policy': 'Datenschutz',
    'I have read and agree to the <0>terms, conditions and privacy policy</0>of SoniQ Services.':
      'Ich habe die <0>Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinien</0> von SoniQ Services gelesen und stimme ihnen zu.',
    'Invalid email address': 'Bitte füllen Sie dieses Feld aus',
    Jobs: 'Jobs',
    'Join Us': 'Karriere',
    Message: 'Nachricht',
    'Monday–Friday': 'Montag–Freitag',
    'Office hours': 'Bürozeiten',
    'Please accept our privacy policy':
      'Bitte akzeptieren Sie unsere Datenschutzbestimmungen',
    'Please fill your name': 'Bitte füllen Sie dieses Feld aus',
    'Please fill your work email': 'Bitte geben Sie Ihre Arbeits-E-Mail ein',
    'Please leave us a short message': 'Bitte füllen Sie dieses Feld aus',
    'Read more': 'Erfahren Sie mehr',
    'Request a demo': 'Termin buchen',
    Send: 'Senden',
    'Sending...': 'Senden...',
    'Sent!': 'Gesendet!',
    Service: 'Service',
    'Site Notice': 'Impressum',
    'Sorry, your message could not be sent. Please try again.':
      'Entschuldigung, Ihre Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut.',
    'Thank you for your message.': 'Vielen Dank für Ihre Nachricht.',
    'Try again': 'Erneut versuchen',
    'We will get back to you as soon as possible.':
      'Wir werden uns so schnell wie möglich bei Ihnen melden.',
    'Work Email': 'Email',
  },
}

import React, { useEffect } from 'react'
import { MDXProvider } from '@mdx-js/react'
import { use100vh } from 'react-div-100vh'
import { setElementVars } from '@vanilla-extract/dynamic'

import { realViewportHeightVar } from '@/styles/theme.css'
import { BreakpointsProvider } from '@/hooks/use-breakpoints'

import 'modern-normalize/modern-normalize.css'
import '@/styles/global.css'

import { Navbar } from './navbar'
import { Footer } from './footer'
import * as MdxComponents from './mdx'
import { ConsentManagerWrapper } from './consent-manager'

const shortcodes = {
  a: MdxComponents.Link,
  h1: (props) => <MdxComponents.Headline level="1" {...props} />,
  h2: (props) => <MdxComponents.Headline level="2" {...props} />,
  h3: (props) => <MdxComponents.Headline level="3" {...props} />,
  h4: (props) => <MdxComponents.Headline level="4" {...props} />,
  h5: (props) => <MdxComponents.Headline level="5" {...props} />,
  h6: (props) => <MdxComponents.Headline level="6" {...props} />,
  p: MdxComponents.Paragraph,
  ...MdxComponents,
}

export default function DefaultPageLayout({
  children,
  showHeader = true,
  transparentHeader = false,
  showFooter = true,
}) {
  const realViewportHeight = use100vh()
  useEffect(() => {
    setElementVars(document.documentElement, {
      [realViewportHeightVar]: `${realViewportHeight}px`,
    })
  }, [realViewportHeight])

  return (
    <ConsentManagerWrapper>
      <BreakpointsProvider>
        <MDXProvider components={shortcodes}>
          {showHeader && <Navbar transparent={transparentHeader} />}
          <main style={{ overflow: `hidden` }}>{children}</main>
          {showFooter && <Footer />}
        </MDXProvider>
      </BreakpointsProvider>
    </ConsentManagerWrapper>
  )
}

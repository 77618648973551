import React, { useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useLang } from 'hoofd'

import { PageContext, PageContextInterface } from '@/contexts/page'
import { useMediaPresetMap } from '@/hooks/use-media-preset-map'
import DefaultPageLayout from '@/components/default-page-layout'
import { ContentfulPageContext } from '@/types'

const PageTemplate: React.FC<
  PageProps<Queries.ContentfulPageTemplateQuery>
> = ({
  data,
  pageContext,
}: {
  data: Queries.ContentfulPageTemplateQuery
  pageContext: ContentfulPageContext
}) => {
  const mediaPresetMap = useMediaPresetMap(
    data.contentfulPage.mdx.childMdx.media,
  )
  const pageContextValue: PageContextInterface = useMemo(
    () => ({ media: mediaPresetMap, pageContext }),
    [mediaPresetMap, pageContext],
  )

  const { showHeader, transparentHeader, showFooter } = data.contentfulPage

  return (
    <PageContext.Provider value={pageContextValue}>
      <DefaultPageLayout
        showHeader={showHeader}
        transparentHeader={transparentHeader}
        showFooter={showFooter}
      >
        <MDXRenderer>{data.contentfulPage.mdx.childMdx.body}</MDXRenderer>
      </DefaultPageLayout>
    </PageContext.Provider>
  )
}

export const pageQuery = graphql`
  query ContentfulPageTemplate($id: String!) {
    contentfulPage(id: { eq: $id }) {
      locale: node_locale
      title
      metaDescription
      metaImage {
        file {
          url
        }
      }
      showHeader
      transparentHeader
      showFooter
      mdx {
        childMdx {
          body
          media {
            ...MdxMedia
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    defaultSocialShareImage: contentfulAsset(
      contentful_id: { eq: "203b0PDk4WZmUmfVEpeftj" }
    ) {
      file {
        url
      }
    }
  }
`

export default PageTemplate

export const Head = ({ data, location }) => {
  const { title, metaDescription, metaImage, locale } = data.contentfulPage
  const { url, title: siteTitle, description } = data.site.siteMetadata

  const socialShareImage =
    metaImage?.file?.url || data.defaultSocialShareImage.file.url

  useLang(locale)

  return (
    <>
      <title>{title === siteTitle ? title : `${title} | ${siteTitle}`}</title>
      <meta name="description" content={metaDescription || description} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${url}${location.pathname}`} />
      <meta
        property="og:image"
        content={`https:${socialShareImage}?w=1200&h=630&fit=fill`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        property="twitter:image"
        content={`ttps:${socialShareImage}?w=1200&h=628&fit=fill`}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta name="format-detection" content="telephone=no" />
      <link rel="canonical" href={`${url}${location.pathname}`} />
      <link rel="preconnect" href="https://images.ctfassets.net" />
      <link rel="dns-prefetch" href="https://images.ctfassets.net" />
      <link rel="preconnect" href="https://cdn.polyfill.io" />
      <link rel="dns-prefetch" href="https://cdn.polyfill.io" />
    </>
  )
}

import { useEffect, useState } from 'react'

export const useMounted = (): boolean => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}

import React, { useMemo } from 'react'
import { useCountUp } from 'use-count-up'
import { useIntersection } from 'react-use'

import * as styles from './fact.css'

interface FactProps {
  children: React.ReactNode
  /** The number that will be counted up. */
  value: string
  /** The small title below the number */
  title: string
}

/** Shows a fact with big animated up counting number. */
export const Fact: React.FC<FactProps> = ({
  title,
  value,
  children,
  ...props
}) => {
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    threshold: 1,
  })

  const isVisible = useMemo(
    () => intersection?.isIntersecting ?? false,
    [intersection?.isIntersecting],
  )

  const { value: counter } = useCountUp({
    isCounting: isVisible,
    start: 0,
    end: parseInt(value, 10),
    easing: `easeOutCubic`,
    duration: 3,
  })

  return (
    <div className={styles.fact} {...props} ref={intersectionRef}>
      <div className={styles.top}>
        <div className={styles.value}>{counter}</div>
        <div className={styles.title}>{title}</div>
      </div>
      {children}
    </div>
  )
}

import React, { useMemo } from 'react'

import { Image, BaseImageProps } from '@/components/image'

import * as styles from './background-image.css'

interface BackgroundImageProps extends Omit<BaseImageProps, 'preset'> {
  /** The opacity of the image. Must be a value between 0 and 1. */
  opacity: string
}

/**
 * This will draw a background image to next container parent. Usually these are <Section/> or <Box/>
 */
export const BackgroundImage: React.FC<BackgroundImageProps> = ({
  opacity = `1`,
  fit = `cover`,
  ...props
}) => {
  const style = useMemo(() => {
    const customStyle = { ...(props.style || {}) }
    if (parseInt(opacity, 10) < 1) {
      customStyle.opacity = opacity
    }
    return customStyle
  }, [opacity])
  return (
    <Image
      preset="screen"
      className={styles.wrapper}
      classNameImg={styles.image}
      fit={fit}
      style={style}
      {...props}
    />
  )
}

import { navbarHeightVar } from '@/styles/theme.css'

export const smoothScrollTo = (selector, verticalAlign = `top`) => {
  if (!selector) {
    return
  }
  const anchor = document.querySelector(selector)
  if (!anchor) {
    return
  }
  const scrollDistance = window.pageYOffset + anchor.getBoundingClientRect().top
  const anchorHeight = anchor.offsetHeight
  const windowHeight = window.innerHeight

  // Modifiy scroll offset via --floating-header-height css variable
  const modifier =
    parseInt(
      window
        .getComputedStyle(document.body)
        .getPropertyValue(navbarHeightVar.slice(4, navbarHeightVar.length - 1)),
      10,
    ) || 0

  // Adjust offset if vertical align is not top
  let scrollOffset = modifier
  if (verticalAlign === `center`) {
    scrollOffset = (windowHeight + modifier) / 2 - anchorHeight / 2
  }
  if (verticalAlign === `end`) {
    scrollOffset = windowHeight - anchorHeight
  }

  const scrollPos = scrollDistance - scrollOffset
  window.scrollTo({ top: scrollPos, behavior: `smooth` })
}

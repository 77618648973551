import "src/styles/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4WX21IiMRCG73mKlNzIlriTnvN4s4JStVd74e4DjDAIKgzOAOJu8e7bQV2S7majVR4++k8mIf1Fi6au1+pPR6l+v5481k+7oFBJsNpdWUgXSgfBdnbV2XcKFoBCBXZ1iL9fQtxUC5tGhlIY48AEJYhYXVooICgrVEhQXqiIoLJQdKx7XB9B40JlBE3MY2QpDVf8gaccPSDSkLmbOHvfxFebzQs1Lp/H54cv27I5/7fjPfUF9wu/9ZXNg17Pzj968uDJP3nyoSf/7MlHnvzCk489+aUnn3jytSefevIrTz7z5F88+dyTbz7yJPrfTIuNk12C6eYjXGNT4Hl34aZQ3WQwiG4HNt0ihWEe3YJNXw0dxTfX1zbdIdXhcDiMbPpmaoP8Onfob6TT6dTxDrqoG2Tm08Hoo+5NpgfByMEoou4oGMHIGVejj85+rKqluiuX7dmFOrurHupK/fqOP/8sZ/WivFAtvtRvq2buzo/SCgNHbhqVlRKEyooISnkw41U5HwuFReV7L/pUjw0OmaH0RBKtrgzlxYK89INkYD3jCtZz7mCNSsrxg6wBRaPVV6UdiPbQCdLcoeiECGHoQGx0U6ldWpsnTXT2PvK+c/lxAYFzP+mVvPYXYe2NXNqaiVjx2hTzO0JvTDV/w7bCfK/iXu8O0/ER3gxPKf0tV0MgVQP2DkjVYDijoUgj/r5DLLDDXR46ngDTGXyHIRNKzX3OJy+FSuFOh7FQN+EXPVRC3Yz1KmCnhJF7qgE7JUoIw56YL2dokrWDn1mrA3YKhCSNfRJqwmoexR4BWoYtAqTtoOHRlXSU4eWwCZRu+Da0/BDDWjrEsGMKBKEDQOwAMCed7rY552SBIZ7x2J0k1KYrg9QMuO98W1STeanacVPhBVAuJ+p8MV/2X+eTNb7HaYJ/o/UOsnDVcVIesj6oQDKpvnWs4GpEGGhj7wuxiNW/1CUp5webhGLk7Sh1KpREqDdKQXlIy6NeOWWWU26R7SL7RTbMScdQy9BqRzNUNLTYMg11DS21ZMN0Q+DRN65xoo//IVznCCtG6/Tx1OuYzXcUj6ueT5m58vnUmagfWUCugsKY0UYawGiILw57CjjdSLvTnuqDdx3xPrCEJCvplJSIlvjzYb8AW7alJllOSu07+789aJqFfw8AAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/mdx/custom/popup.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/mdx/custom/popup.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71Uu7KbMBDt/RWb4s7gxGLw27GbfEZaGS2ga5AYIWyuM/73CElgwC5SpYCRln2c3XOWsLhE5WEbwZ8ZQCkrrrkUR0h4g+xkTHfCBcPmCMsoilpDjok+gj1qWfrTjTOdWZ+P9pohTzP9vJ9pfEmVrAU7wpWqgBDJPuWluc9Ps8csdBCW/w2CSs802OwX8HO9gOU+WkAUblZDLKsJFnquZF5rnGYSUjibVAzV865c8QnEkjLGRWpAKSxaQyKFJhW/ozGFe2+Ma1VJk6uUXGhUA1Rri0peUSW5vB0h44yhOA1s5OsIVaxknr+bwWREPZyVrdyX2dgyjFdlTk2+JMfmXbaUloNOjBNhXGHsBhabcRUW2mddaZ58kdj0isLEx+jaAqA5TwXhGovqae5hbCccKMyp5lecSGIQsfvXCICCqpQLcpZay8LQE659I/5DR2Bvv2UGJ6lKGmNL803RcuBuOV6Ox7gfj7GVBnzjRSmVpkK3jr8KZJy2hCEKoIJBUJhknqb97lA2c5skHC5pp/+to8ELLKZ5HBS0CYb7Fc3hh2V3ATvzns9dgHHritiwYUg6h++m7YN39W6H6Hpzhk4FtNbSWZz2iaKM14ZHA7qzN6TKKGuVGpUNbPzzbvtWW19PKyqqRCrDiT0aAvF3QEyv1uHxHMbaD6NtpgNluzFYMyAwnoNP30u+o3WQcOMTvtfPq8SV9CN5UbhVCTmjvqFbz0GVra/iB7s7hKuPETanpe0rvp2PdFtTYZ643SQoWMfrU4zRJHrvo3s9nnMZX0aCHDff/fJeXPpNikYSGcvRfxv+Bx+zx1/Mg6U5bQYAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var __esModule = true;
export var backdrop = 'mk0p851';
export var backgroundImage = 'mk0p857';
export var button = 'mk0p856';
export var close = 'mk0p852';
export var content = 'mk0p855';
export var contentWrapper = 'mk0p854';
export var dialog = 'mk0p850';
export var scrollWrapper = 'mk0p853';
/**
 * Based on https://medium.com/better-programming/how-to-use-media-queries-programmatically-in-react-4d6562c3bc97
 */

import { mediaQueries } from '@/styles/media-queries'
import React, { useState, useEffect, createContext, useContext } from 'react'

const defaultValue = {}

Object.keys(mediaQueries).forEach((queryName) => {
  defaultValue[queryName] = false
})

type BreakpointInterface = Record<keyof typeof mediaQueries, boolean>

const BreakpointContext = createContext<BreakpointInterface | null>(
  defaultValue as BreakpointInterface,
)

export const BreakpointsProvider = ({ children }) => {
  const [queryMatch, setQueryMatch] = useState({})

  useEffect(() => {
    const mediaQueryLists = {}
    const keys = Object.keys(mediaQueries)
    let isAttached = false

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        )
        return acc
      }, {})
      setQueryMatch(updatedMatches)
    }

    if (window && window.matchMedia) {
      const matches = {}
      keys.forEach((media) => {
        if (typeof mediaQueries[media] === `string`) {
          mediaQueryLists[media] = window.matchMedia(mediaQueries[media])
          matches[media] = mediaQueryLists[media].matches
        } else {
          matches[media] = false
        }
      })
      setQueryMatch(matches)
      isAttached = true
      keys.forEach((media) => {
        if (typeof mediaQueries[media] === `string`) {
          mediaQueryLists[media].addListener(handleQueryListener)
        }
      })
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof mediaQueries[media] === `string`) {
            mediaQueryLists[media].removeListener(handleQueryListener)
          }
        })
      }
    }
  }, [mediaQueries])

  return (
    <BreakpointContext.Provider value={queryMatch as BreakpointInterface}>
      {children}
    </BreakpointContext.Provider>
  )
}

export function useBreakpoints() {
  const context = useContext(BreakpointContext)
  if (context === defaultValue) {
    throw new Error(`useBreakpoint must be used within BreakpointProvider`)
  }
  return context
}

import "src/styles/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4WX21IiMRCG73mKlNzIlriTnvN4s4JStVd74e4DjDAIKgzOAOJu8e7bQV2S7majVR4++k8mIf1Fi6au1+pPR6l+v5481k+7oFBJsNpdWUgXSgfBdnbV2XcKFoBCBXZ1iL9fQtxUC5tGhlIY48AEJYhYXVooICgrVEhQXqiIoLJQdKx7XB9B40JlBE3MY2QpDVf8gaccPSDSkLmbOHvfxFebzQs1Lp/H54cv27I5/7fjPfUF9wu/9ZXNg17Pzj968uDJP3nyoSf/7MlHnvzCk489+aUnn3jytSefevIrTz7z5F88+dyTbz7yJPrfTIuNk12C6eYjXGNT4Hl34aZQ3WQwiG4HNt0ihWEe3YJNXw0dxTfX1zbdIdXhcDiMbPpmaoP8Onfob6TT6dTxDrqoG2Tm08Hoo+5NpgfByMEoou4oGMHIGVejj85+rKqluiuX7dmFOrurHupK/fqOP/8sZ/WivFAtvtRvq2buzo/SCgNHbhqVlRKEyooISnkw41U5HwuFReV7L/pUjw0OmaH0RBKtrgzlxYK89INkYD3jCtZz7mCNSsrxg6wBRaPVV6UdiPbQCdLcoeiECGHoQGx0U6ldWpsnTXT2PvK+c/lxAYFzP+mVvPYXYe2NXNqaiVjx2hTzO0JvTDV/w7bCfK/iXu8O0/ER3gxPKf0tV0MgVQP2DkjVYDijoUgj/r5DLLDDXR46ngDTGXyHIRNKzX3OJy+FSuFOh7FQN+EXPVRC3Yz1KmCnhJF7qgE7JUoIw56YL2dokrWDn1mrA3YKhCSNfRJqwmoexR4BWoYtAqTtoOHRlXSU4eWwCZRu+Da0/BDDWjrEsGMKBKEDQOwAMCed7rY552SBIZ7x2J0k1KYrg9QMuO98W1STeanacVPhBVAuJ+p8MV/2X+eTNb7HaYJ/o/UOsnDVcVIesj6oQDKpvnWs4GpEGGhj7wuxiNW/1CUp5webhGLk7Sh1KpREqDdKQXlIy6NeOWWWU26R7SL7RTbMScdQy9BqRzNUNLTYMg11DS21ZMN0Q+DRN65xoo//IVznCCtG6/Tx1OuYzXcUj6ueT5m58vnUmagfWUCugsKY0UYawGiILw57CjjdSLvTnuqDdx3xPrCEJCvplJSIlvjzYb8AW7alJllOSu07+789aJqFfw8AAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/mdx/custom/accordion.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/mdx/custom/accordion.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41U246bMBR8z1dYK0UKUo2ABsoSVep/VH0w+ADeGBvZZpNtlX+vL0lg0W6bN/vMeM7dse6M5iJBfzYIjVIzw6SokAJODHuFg7VSpkdO3ipUc9kcnWUklDLRYQ6tqVAa5woGZ69Jc+yUnAStkJDCv66loqDmezMpLe19lEwYUM7USmGwZr+hQq9E7TCW9EUez9kQOZQzAbgH1vVmhYvo/vr0AZ6WHm8kd/6Y6EExc9hcNvE1559EMYLhPBJBgX5/MmqCp19Vy5Q2uOkZp74qt4gptGTiDyj4V5+H9RwtJLLgIsQYaAFIffBzR0itJZ+Mr2Goe+KORo4VypOtPysidCvVUIWjbSHsku0XhC0hQroh3N7jIl8G8PXfATyuiZQ0DnxOKHRLD/swW5/MzEBUxwSupTFyeF+pdqlSeJX7LIZxuqOlR0+Mmr5CRRln21l7LYrIZKSr3mXzYwDKiM1CAQhkm4h2gw3mqvOtKMdz5JXj5Zqsk/GNuMys7MoKKJ5zXXD2DynlV9Y97U4xevAmd8IGhtF1BNv+TYPQFdpnW7Qvt4HzMmnD2jeL2lUTVl+PpAFcgznZfFe+irWv+7ovSOWadFvry3+rmWZlsirnzeViyMJApXGaR4f3GJaK+W4qt1Oogdv3YV3/Ba1US1nGBAAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var __esModule = true;
export var accordion = 'sgtsln5';
export var content = 'sgtsln4';
export var media = 'sgtsln8';
export var mediaWrapper = 'sgtsln6';
export var slidesWrapper = 'sgtsln7';
export var toggle = 'sgtsln0';
export var toggleColorVar = 'var(--sgtsln1)';
export var toggleIcon = 'sgtsln2';
export var toggleIconOpen = 'sgtsln3';
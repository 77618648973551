import { PageContext } from '@/contexts/page'
import React, { useContext } from 'react'

export function useVideo({ preset, id }) {
  const { media: mediaContextData } = useContext(PageContext)

  const contextData = mediaContextData.get(preset)

  if (!contextData) {
    throw new Error(`Media preset ${preset} does not exist`)
  }

  const videoData = contextData.get(id)

  if (!videoData) {
    throw new Error(`Video ${id} does not exist in preset ${preset}.`)
  }

  const { aspectRatio } = videoData.videoH264

  const sources = [
    { name: `videoH265`, type: `video/mp4; codecs=hevc` },
    { name: `videoVP9`, type: `video/webm; codecs=vp9,opus` },
    { name: `videoH264`, type: `video/mp4; codecs=avc1.4d4032` },
  ]
    .filter(({ name }) => !!videoData[name])
    .map(({ name, type }) => (
      <source key={name} src={videoData[name].path} type={type} />
    ))

  if (!sources) {
    throw new Error(
      `Video ${id} in preset ${preset} does not have any valid video tracks`,
    )
  }

  const screenshots = videoData.videoScreenshots

  return { aspectRatio, sources, screenshots }
}

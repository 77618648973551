/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useCallback, useMemo } from 'react'
import { useIntersection } from 'react-use'

import { useVideo } from '@/hooks/use-video'
import {
  AspectRatioRecipe,
  aspectRatioRecipe,
  aspectRatioVar,
} from '@/styles/recipies/dimensions.css'

import { assignInlineVars } from '@vanilla-extract/dynamic'
import * as styles from './video.css'

interface VideoProps extends AspectRatioRecipe {
  /** Id of the video to embed */
  id: string
  /** Maximum width the video player will grow to */
  width?: string
  /** Should the video automatically start playing? **Requires muted**. */
  autoPlay?: boolean
  /** Should the controls be display? */
  controls?: boolean
  /** Should the audio be muted? */
  muted?: boolean
  /** Should the video pause when the user hovers the video? */
  pauseOnHover?: boolean
  /** Select another screenshot */
  screenshotIndex?: string
  /** Preloading behaviour */
  preload?: string
  /** Define in which size the video is being rendered on desktop size. Set this properly to ensure the page loads fast. Do not change videos that should be shown in fullscreen. */
  preset?: string
  className?: string
}

/**
 * Renders an internal video. For external videos use `<YoutubeVideo />` or similar.
 *
 * Autoplay will enforce audio mute due to browser limitations.
 *
 * @example
 * <Video id="randomVideoId" />
 */
export const Video: React.FC<VideoProps> = ({
  id,
  screenshotIndex = `0`,
  controls = true,
  autoPlay = false,
  preload = `metadata`,
  muted = false,
  pauseOnHover,
  width,
  aspectRatio,
  preset = `full`,
  className,
  ...props
}) => {
  const {
    aspectRatio: videoAspectRatio,
    sources,
    screenshots,
  } = useVideo({ preset, id })
  const refVideo = useRef(null)

  const handleVideoMouseEnter = useCallback(() => {
    if (autoPlay && pauseOnHover) {
      refVideo.current.pause()
    }
  }, [refVideo, autoPlay, pauseOnHover])

  const handleVideoMouseLeave = useCallback(() => {
    if (autoPlay && pauseOnHover) {
      refVideo.current.play()
    }
  }, [refVideo, autoPlay, pauseOnHover])
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: `0px 0px 0px 0px`,
    threshold: 0,
  })

  const isVisible = useMemo(
    () => intersection && intersection.intersectionRatio > 0,
    [intersection],
  )

  // Autoplay logic
  useEffect(() => {
    // Autoplay as soon video is visible to the user.
    if (autoPlay && isVisible && refVideo.current && refVideo.current.paused) {
      refVideo.current.play()
      return () => null
    }
    // Stop video when user leaves the viewport.
    if (
      autoPlay &&
      !isVisible &&
      refVideo.current &&
      refVideo.current.playing
    ) {
      refVideo.current.pause()
    }
    return () => null
  }, [autoPlay, controls, isVisible])

  const posterSrc = useMemo(
    () =>
      screenshots?.[screenshotIndex]?.childImageSharp?.gatsbyImageData.images
        .fallback.src,
    [screenshotIndex, screenshots],
  )

  let style = { maxWidth: width }

  if (!aspectRatio) {
    style = {
      ...style,
      ...assignInlineVars({
        [aspectRatioVar]: videoAspectRatio,
      }),
    }
  }

  return (
    <div
      className={[className, styles.wrapper].join(` `)}
      style={style}
      ref={intersectionRef}
    >
      <div
        className={[
          aspectRatioRecipe({
            aspectRatio: aspectRatio || `video`,
          }),
        ].join(` `)}
      >
        <video
          {...props}
          ref={refVideo}
          className={styles.video}
          onMouseEnter={handleVideoMouseEnter}
          onMouseLeave={handleVideoMouseLeave}
          controls={controls}
          playsInline={autoPlay || !controls}
          preload={preload}
          muted={autoPlay || muted}
          poster={posterSrc}
        >
          {sources}
        </video>
      </div>
    </div>
  )
}

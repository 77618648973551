/**
 * This file integrates consent-manager to protect our visitors privacy
 * and supports us to align with GDPR and CCPA.
 *
 * Learn more: https://github.com/techboi/consent-manager
 */

import React from 'react'

import createPersistedState from 'use-persisted-state'

import { ConsentManagerDefaultInterface } from '@consent-manager/interface-default'
import '@consent-manager/interface-default/dist/default.min.css'
import { youtubeIntegration } from '@consent-manager/integration-youtube'
import { segmentIntegration } from '@consent-manager/integration-segment'

const useConsentStateStore = createPersistedState(`consent-manager`)

const consentManagerConfig = {
  integrations: [
    youtubeIntegration(),
    segmentIntegration({
      writeKey: `WYqV8I9liQIr7ESin5i1Z6YoTU49z4ir`,
    }),
  ],
}

/**
 * Wraps the apps root element with consent-manager
 * See:
 * * https://github.com/techboi/consent-manager
 * * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
 */
export function ConsentManagerWrapper({ children }) {
  const storage = useConsentStateStore()

  return (
    <ConsentManagerDefaultInterface
      config={consentManagerConfig}
      store={storage}
    >
      {children}
    </ConsentManagerDefaultInterface>
  )
}

import { inlineFontRecipe, InlineFontRecipe } from '@/styles/recipies/font.css'
import React from 'react'

interface FontProps extends InlineFontRecipe {
  children: React.ReactNode
}

export const Font: React.FC<FontProps> = ({
  family,
  color,
  size,
  weight,
  decoration,
  transform,
  ...props
}) => (
  <span
    className={[
      inlineFontRecipe({
        family,
        color,
        size,
        weight,
        decoration,
        transform,
      }),
    ].join(` `)}
    {...props}
  />
)

import React, { useState, useMemo } from 'react'
import { useEvent, useLocalStorage } from 'react-use'
import { graphql, PageProps } from 'gatsby'

import { Preview } from '@/editor/preview'
import { PageContext } from '@/contexts/page'

import { useMediaPresetMap } from '@/hooks/use-media-preset-map'

const PreviewPage: React.FC<PageProps<Queries.PreviewDataQuery>> = ({
  data,
}) => {
  const [initialValue] = useLocalStorage(`mdx-editor`, ``, { raw: true })
  const [value, setValue] = useState(initialValue)

  // Listen for content updates from editor
  useEvent(`storage`, (e) => {
    if (e.key === `mdx-editor`) {
      setValue(e.newValue)
    }
  })

  const processedAssets = useMemo(() => {
    const list = [...data.allFile.nodes, ...data.allContentfulAsset.nodes]

    return {
      screen: list,
      full: list,
      half: list,
      third: list,
      quarter: list,
      sixth: list,
      eight: list,
      people: list,
    }
  }, [data])

  const mediaPresetMap = useMediaPresetMap(
    processedAssets as unknown as Queries.MdxMediaFragment,
  )
  const pageContextValue = useMemo(
    () => ({
      media: mediaPresetMap,
      pageContext: {},
    }),
    [mediaPresetMap],
  )

  return (
    <PageContext.Provider value={pageContextValue}>
      <Preview value={value} />
    </PageContext.Provider>
  )
}

export const pageQuery = graphql`
  query PreviewData {
    allFile(filter: { internal: { mediaType: { glob: "{image,video}/*" } } }) {
      nodes {
        ...MdxFile
        publicURL
      }
    }
    allContentfulAsset(filter: { mimeType: { glob: "{image,video}/*" } }) {
      nodes {
        ...MdxContentfulAsset
      }
    }
  }
`

export default PreviewPage

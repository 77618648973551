import React, { useState } from 'react'
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormMessage as FormMessage,
  unstable_FormCheckbox as FormCheckbox,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form'
import { VisuallyHidden } from 'reakit/VisuallyHidden'
import { Trans, t } from '@lingui/macro'

import { linkRecipe } from '@/components/link.css'
import { Link, LinkProps } from '@/components/link'
import * as styles from './contact-form.css'

interface ContactFormProps {
  /** Override the theme of the submit */
  buttonTheme?: LinkProps['theme']
}

const FormItem = ({ id, label, placeholder, form, ...props }) => (
  <div className={styles.formItem}>
    <VisuallyHidden>
      <FormLabel {...form} name={id}>
        {label}
      </FormLabel>
    </VisuallyHidden>
    <FormInput
      {...form}
      className={styles.formInput}
      name={id}
      placeholder={placeholder}
      {...props}
    />
    <FormMessage {...form} className={styles.formError} name={id} />
  </div>
)

const FormItemCheckbox = ({ id, label, form, ...props }) => (
  <div className={styles.formItem}>
    <div className={styles.checkboxWrapper}>
      <FormCheckbox
        {...form}
        className={styles.formCheckbox}
        name={id}
        {...props}
      />
      <FormLabel
        {...form}
        name={id}
        className={[
          styles.formCheckboxLabel,
          !!form.errors[id] &&
            !!form.touched[id] &&
            styles.formCheckboxLabelError,
        ]
          .filter(Boolean)
          .join(` `)}
      >
        {label}
      </FormLabel>
    </div>
  </div>
)

const Tos = () => (
  <Trans>
    I have read and agree to the{` `}
    <Link id="6Rfk2Hlf44SASEIcYsgq8e" theme="neutral" openInNewTab>
      terms, conditions and privacy policy
    </Link>
    {` `}
    of SoniQ Services.
  </Trans>
)

export const ContactForm: React.FC<ContactFormProps> = ({
  buttonTheme = `button`,
}) => {
  const [formState, setFormState] = useState<
    'pristine' | 'changed' | 'invalid' | 'sending' | 'success' | 'failure'
  >(`pristine`)

  const form = useFormState<{
    name: string
    email: string
    message: string
    caramello: string
    tos: boolean
  }>({
    values: {
      name: ``,
      email: ``,
      message: ``,
      caramello: ``,
      tos: false,
    },
    onValidate: (values) => {
      const errors: { [key: string]: string } = {}
      if (!values.name.trim()) {
        errors.name = t`Please fill your name`
      }
      if (!values.email.trim()) {
        errors.email = t`Please fill your work email`
      } else if (values.email.indexOf(`@`) === -1 || values.email.length < 3) {
        errors.email = t`Invalid email address`
      }
      if (!values.message.trim()) {
        errors.message = t`Please leave us a short message`
      }
      if (!values.tos) {
        errors.tos = t`Please accept our privacy policy`
      }
      if (Object.keys(errors).length) {
        throw errors
      }
    },
    onSubmit: async (values) => {
      setFormState(`sending`)
      try {
        const response = await fetch(`/api/contact`, {
          method: `POST`,
          body: new URLSearchParams({ ...values, tos: `accepted` }),
        })
        if (response.status !== 200) {
          throw new Error(response.body.toString())
        }
        // note: form.reset() destroys the onSubmit. Bug in reakit?
        form.values.name = ``
        form.values.message = ``
        form.values.email = ``
        form.values.tos = false
        setFormState(`success`)
      } catch (err) {
        setFormState(`failure`)
        throw err.message
      }
    },
  })
  return (
    <div className={styles.wrapper}>
      <Form {...form}>
        <FormItem id="name" label="Name" placeholder="Name" form={form} />
        <FormItem
          id="email"
          label={t`Work Email`}
          placeholder={t`Work Email`}
          form={form}
        />
        <FormItem
          id="message"
          label={t`Message`}
          placeholder={t`Message`}
          form={form}
          as="textarea"
          rows="4"
        />
        <FormItemCheckbox form={form} label={<Tos />} id="tos" />
        <FormCheckbox {...form} name="caramello" className={styles.caramello} />
        {formState === `success` && (
          <div className={styles.formStatusMessageSuccess}>
            <Trans>Thank you for your message.</Trans>
            <br />
            <Trans>We will get back to you as soon as possible.</Trans>
          </div>
        )}
        {formState === `failure` && (
          <div className={styles.formStatusMessageFailure}>
            <Trans>
              Sorry, your message could not be sent. Please try again.
            </Trans>
          </div>
        )}
        <div className={styles.wrapperBottom}>
          <FormSubmitButton
            {...form}
            className={[
              linkRecipe({ theme: buttonTheme }),
              styles.submitButton,
            ].join(` `)}
          >
            {[`pristine`, `changed`, `invalid`].includes(formState) && t`Send`}
            {formState === `sending` && t`Sending...`}
            {formState === `success` && t`Sent!`}
            {formState === `failure` && t`Try again`}
          </FormSubmitButton>
        </div>
      </Form>
    </div>
  )
}

import React from 'react'

import icons from '@/icons'

import * as styles from './icon.css'

export interface IconsProps {
  /** Name of the icon to display */
  name: keyof typeof icons
  className?: string
  [key: string]: any
}

export const Icon: React.FC<IconsProps> = ({ name, className, ...props }) => {
  const icon = icons[name]
  if (!icon) {
    return null
  }
  return (
    <svg
      viewBox={icon.viewBox}
      className={[styles.svg, className].join(` `)}
      {...props}
    >
      <use xlinkHref={icon.url} />
    </svg>
  )
}

import {
  blockFontRecipe,
  BlockFontRecipe,
  inlineFontRecipe,
  InlineFontRecipe,
} from '@/styles/recipies/font.css'
import React from 'react'

import * as styles from './headline.css'

interface HeadlineProps extends BlockFontRecipe, InlineFontRecipe {
  children: React.ReactNode
  /** The level of the headline. From 1 to 6. */
  level?: '1' | '2' | '3' | '4' | '5' | '6'
}

/** Renders containing text as a headline. */
export const Headline: React.FC<HeadlineProps> = ({
  level = `1`,
  children,
  textAlign,
  family,
  color,
  size,
  weight,
  decoration,
  transform,
  ...props
}) =>
  React.createElement(
    `h${level}`,
    {
      className: [
        styles.headline,
        blockFontRecipe({ textAlign }),
        inlineFontRecipe({
          family,
          color,
          size,
          weight,
          decoration,
          transform,
        }),
      ].join(` `),
      ...props,
    },
    children,
  )

/*eslint-disable*/ module.exports = {
  messages: {
    '9:00 – 18:00 CET': '9:00 – 18:00 CET',
    'All rights reserved': 'All rights reserved',
    Contact: 'Contact',
    'Data Policy': 'Data Policy',
    'I have read and agree to the <0>terms, conditions and privacy policy</0>of SoniQ Services.':
      'I have read and agree to the <0>terms, conditions and privacy policy</0>of SoniQ Services.',
    'Invalid email address': 'Invalid email address',
    Jobs: 'Jobs',
    'Join Us': 'Join Us',
    Message: 'Message',
    'Monday–Friday': 'Monday–Friday',
    'Office hours': 'Office hours',
    'Please accept our privacy policy': 'Please accept our privacy policy',
    'Please fill your name': 'Please fill your name',
    'Please fill your work email': 'Please fill your work email',
    'Please leave us a short message': 'Please leave us a short message',
    'Read more': 'Read more',
    'Request a demo': 'Request a demo',
    Send: 'Send',
    'Sending...': 'Sending...',
    'Sent!': 'Sent!',
    Service: 'Service',
    'Site Notice': 'Site Notice',
    'Sorry, your message could not be sent. Please try again.':
      'Sorry, your message could not be sent. Please try again.',
    'Thank you for your message.': 'Thank you for your message.',
    'Try again': 'Try again',
    'We will get back to you as soon as possible.':
      'We will get back to you as soon as possible.',
    'Work Email': 'Work Email',
  },
}

import React from 'react'
import { ContextMediaMapInterface } from '../contexts/page'

// Turns GraphQL result into our Context Map
export function useMediaPresetMap(
  contextData: Queries.MdxMediaFragment,
): ContextMediaMapInterface {
  const mediaPresetMap = React.useMemo(() => {
    const presetsMap = new Map()
    if (contextData) {
      Object.keys(contextData).forEach((presetKey) => {
        const presetDataMap = new Map()
        const contextMedia = contextData[presetKey]
        contextMedia.forEach((media) => {
          const assetId = media.locale
            ? `${media.assetId}-${media.locale}`
            : media.assetId
          presetDataMap.set(assetId, media)
        })
        presetsMap.set(presetKey, presetDataMap)
      })
    }

    return presetsMap
  }, [contextData])

  return mediaPresetMap
}

import {
  AspectRatioRecipe,
  aspectRatioRecipe,
} from '@/styles/recipies/dimensions.css'
import React from 'react'
import ReactYoutube from 'react-youtube'
import { PrivacyShield } from '@consent-manager/core'
import { youtubeIntegration } from '@consent-manager/integration-youtube'

import * as styles from './youtube-video.css'

const integrationId = youtubeIntegration().id

interface YoutubeVideoProps extends AspectRatioRecipe {
  /** Youtube ID of the video to embed. An URL with "https://www.youtube.com/watch?v=dQw4w9WgXcQ" points to the ID "dQw4w9WgXcQ" */
  id: string
}

/**
 * Renders a video from YouTube. For internal videos use `<Video />`.
 *
 * Supports all Youtube player parameters:
 * https://developers.google.com/youtube/player_parameters#Parameters
 *
 * @example
 * <YoutubeVideo id="dQw4w9WgXcQ" />
 */
export const YoutubeVideo: React.FC<YoutubeVideoProps> = ({
  id,
  aspectRatio = `video`,
  ...props
}) => {
  const opts = {
    width: `100%`,
    height: `100%`,
    playerVars: { modestbranding: 1, ...props },
  }

  return (
    <div className={aspectRatioRecipe({ aspectRatio })}>
      <div className={styles.wrapper}>
        <PrivacyShield
          id={integrationId}
          // fallbackUrl={`https://www.youtube.com/watch?v=${id}`}
        >
          <ReactYoutube
            iframeClassName={styles.video}
            videoId={id}
            opts={opts}
          />
        </PrivacyShield>
      </div>
    </div>
  )
}

import React from 'react'
import {
  useDialogState,
  Dialog,
  DialogBackdrop,
  DialogDisclosure,
} from 'reakit/Dialog'

import { Icon } from '@/components/icon'
import { Link } from '@/components/link'
import { Image } from '@/components/image'
import { useMounted } from '@/hooks/use-mounted'

import * as styles from './popup.css'

interface PopupProps {
  children: React.ReactNode
  /** Title of the popup. Needs to be set for accessability reasons and might mirror the first headline in the popups content. */
  title: string
  /** Contentful image id of the background image. Displayed in the top right corner. */
  backgroundImageId?: string
  /** The label of the call to action button. */
  buttonLabel?: string
  /** Url the call to action button should point at. */
  buttonHref?: string
}

/** Shows a popup as soon the page is visited */
export const Popup: React.FC<PopupProps> = ({
  children,
  title,
  backgroundImageId,
  buttonLabel,
  buttonHref,
}) => {
  const dialog = useDialogState({ modal: true, visible: true })
  const hasMounted = useMounted()

  // Prevent hydration error
  if (!hasMounted) {
    return null
  }
  return (
    <DialogBackdrop {...dialog} className={styles.backdrop}>
      <Dialog
        {...dialog}
        className={styles.dialog}
        aria-label={title}
        preventBodyScroll
      >
        <div className={styles.scrollWrapper}>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>{children}</div>
            {buttonLabel && buttonHref && (
              <Link className={styles.button} theme="button" href={buttonHref}>
                {buttonLabel}
              </Link>
            )}
            {backgroundImageId && (
              <Image
                className={styles.backgroundImage}
                preset="third"
                id={backgroundImageId}
                fit="contain"
              />
            )}
          </div>
        </div>
        <DialogDisclosure className={styles.close} {...dialog}>
          <Icon name="close" />
        </DialogDisclosure>
      </Dialog>
    </DialogBackdrop>
  )
}

import arrow from './assets/icons/arrow.svg'
import close from './assets/icons/close.svg'
import menuClose from './assets/icons/menu-close.svg'
import menuOpen from './assets/icons/menu-open.svg'
import newPill from './assets/icons/new.svg'
import toggle from './assets/icons/toggle.svg'

export default {
  arrow,
  close,
  menuClose,
  menuOpen,
  newPill,
  toggle,
}

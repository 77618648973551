import type * as Monaco from 'monaco-editor'

import { useEffect, useMemo, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { vars, responsiveVars } from '@/styles/theme.css'

import * as mdxComponents from '../components/mdx/index'

const COMPONENT_DESCRIPTOR_QUERY = graphql`
  query MonacoCodeCompletionComponentDescriptors {
    allComponentMetadata {
      nodes {
        displayName
        description {
          text
        }
        props {
          name
          type {
            name
          }
          tsType
          required
          defaultValue {
            value
          }
          description {
            text
          }
        }
      }
    }
  }
`

interface Range {
  startLineNumber: number
  endLineNumber: number
  startColumn: number
  endColumn: number
}

function getPropertySnippet({ name, type }, index = 1) {
  if (type === `bool`) {
    return name
  }

  if (type === `object`) {
    return `${name}={{\${${index}:}}}`
  }

  return `${name}="\${${index}:}"`
}

function createMdxComponentProposals({
  monaco,
  components,
  range,
}: {
  monaco: typeof Monaco
  components: ComponentDescriptorMap
  range: Range
}) {
  const completionProposals = []
  for (const componentDescriptor of components.values()) {
    const hasChildren = componentDescriptor.props.find(
      ({ name }) => name === `children`,
    )
    const requiredProps = componentDescriptor.props
      .filter(({ required }) => !!required)
      .filter(({ name }) => name !== `children`)
    const requirePropsSnippets = requiredProps
      .map((prop: { name: any; type: any }, i: number) =>
        getPropertySnippet(prop, i + 1),
      )
      .join(` `)
    const cursorPos = requiredProps.length + 1
    const snippetCloseTag = hasChildren
      ? `>\${${requiredProps.length + 2}:}</${componentDescriptor.name}>`
      : `/>`

    completionProposals.push({
      label: componentDescriptor.name,
      kind: monaco.languages.CompletionItemKind.Function,
      detail: componentDescriptor.description,
      insertText: `${componentDescriptor.name} ${requirePropsSnippets}\${${cursorPos}:}${snippetCloseTag}`,
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range,
    })
  }
  return completionProposals
}

export function registerMdxComponentAutocomplete({
  monaco,
  components,
}: {
  monaco: typeof Monaco
  components: ComponentDescriptorMap
}) {
  monaco.languages.registerCompletionItemProvider(`markdown`, {
    triggerCharacters: [`<`],
    provideCompletionItems(model, position) {
      const textUntilPosition = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: Math.min(0, position.column - 1),
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      })
      const match = textUntilPosition === `<`
      if (!match) {
        return { suggestions: [] }
      }
      const word = model.getWordUntilPosition(position)
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      }
      return {
        suggestions: createMdxComponentProposals({ monaco, components, range }),
      }
    },
  })
}

interface ComponentProps {
  name: string
  description: string
  defaultValue: any
  required: boolean
  type: string
  tsType: any
}

interface ComponentDescriptor {
  name: string
  props: ComponentProps[]
  description?: string
}

type ComponentDescriptorMap = Map<string, ComponentDescriptor>

function createMdxComponentPropsProposals({
  monaco,
  component,
  range,
}: {
  monaco: typeof Monaco
  component: ComponentDescriptor
  range: Range
}) {
  return component.props
    .filter(({ name, description }) => name !== `children` && !!description)
    .map(({ name, description, type }) => ({
      label: name,
      kind: monaco.languages.CompletionItemKind.Property,
      documentation: description,
      insertText: getPropertySnippet({ name, type }),
      insertTextRules:
        monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
      range,
    }))
}

const propValueMap: { [key: string]: string[] } = {
  "alignByColumnVariants['alignX']": [`left`, `center`, `right`, `stretch`],
  "alignByColumnVariants['alignY']": [`top`, `center`, `bottom`, `stretch`],
  "alignByRowVariants['alignX']": [`left`, `center`, `right`, `stretch`],
  "alignByRowVariants['alignY']": [`top`, `center`, `bottom`, `stretch`],
  "blockFontVariants['textAlign']": [`left`, `center`, `right`, `justify`],
  "inlineFontVariants['family']": Object.keys(vars.fonts),
  "inlineFontVariants['color']": Object.keys(vars.color),
  "inlineFontVariants['size']": Object.keys(responsiveVars.text),
  "inlineFontVariants['weight']": Object.keys(vars.fontWeights),
  "inlineFontVariants['decoration']": [
    `overline`,
    `underline`,
    `line-through`,
    `no-underline`,
  ],
  "inlineFontVariants['transform']": [
    `capitalize`,
    `lowercase`,
    `uppercase`,
    `normal-case`,
  ],
}

function createMdxComponentPropValueProposals({
  monaco,
  component,
  property,
  range,
}: {
  monaco: typeof Monaco
  component: ComponentDescriptor
  property: string
  range: Range
}) {
  const propDefinition = component.props.find(({ name }) => name === property)

  if (!propDefinition) {
    return []
  }
  let propValues =
    propValueMap[propDefinition.tsType?.raw ?? propDefinition.type]
  if (Array.isArray(propDefinition.tsType?.elements)) {
    propValues = propDefinition.tsType.elements
      .map(({ name, value }: { name: string; value: string }) =>
        name === `literal` ? value.slice(1, value.length - 1) : null,
      )
      .filter(Boolean)
  }
  if (!propValues) {
    console.warn(`No prop value suggestions found for:`)
    console.warn(propDefinition)
    return []
  }
  return propValues.map((value) => ({
    label: value,
    kind: monaco.languages.CompletionItemKind.Value,
    documentation: propDefinition.description,
    insertText: value,
    insertTextRules:
      monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    range,
  }))
}

const MATCH_PROPERTY_NAME = /\s(\w+)="(?:(?!="[^"]+"))+$/gm
const MATCH_COMPONENT_NAME = /<(\w+)/gm

function registerMdxComponentPropertyAutocomplete({
  monaco,
  components,
}: {
  monaco: typeof Monaco
  components: ComponentDescriptorMap
}) {
  monaco.languages.registerCompletionItemProvider(`markdown`, {
    triggerCharacters: [` `],
    provideCompletionItems(model, position) {
      const textUntilPosition = model.getValueInRange({
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      })
      const isWithinProperty = MATCH_PROPERTY_NAME.test(textUntilPosition)
      const match = [...textUntilPosition.matchAll(MATCH_COMPONENT_NAME)]

      if (!match.length || isWithinProperty) {
        return { suggestions: [] }
      }

      const component = match[match.length - 1][1]

      const componentDescriptor = components.get(component)

      if (!componentDescriptor) {
        return { suggestions: [] }
      }

      const word = model.getWordUntilPosition(position)
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      }

      return {
        suggestions: createMdxComponentPropsProposals({
          monaco,
          component: componentDescriptor,
          range,
        }),
      }
    },
  })
}

function registerMdxComponentPropertyValueAutocomplete({
  monaco,
  components,
}: {
  monaco: typeof Monaco
  components: ComponentDescriptorMap
}) {
  monaco.languages.registerCompletionItemProvider(`markdown`, {
    triggerCharacters: [`"`],
    provideCompletionItems(model, position) {
      const textUntilPosition = model.getValueInRange({
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      })
      const matchComponent = [
        ...textUntilPosition.matchAll(MATCH_COMPONENT_NAME),
      ]
      const matchProperty = [...textUntilPosition.matchAll(MATCH_PROPERTY_NAME)]

      if (!matchComponent.length || !matchProperty.length) {
        return { suggestions: [] }
      }

      const component = matchComponent[matchComponent.length - 1][1]
      const property = matchProperty[matchProperty.length - 1][1]

      const componentDescriptor = components.get(component)

      const word = model.getWordUntilPosition(position)
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      }
      return {
        suggestions: createMdxComponentPropValueProposals({
          monaco,
          component: componentDescriptor,
          property,
          range,
        }),
      }
    },
  })
}

function useComponentDescriptorMap(
  result: Queries.MonacoCodeCompletionComponentDescriptorsQuery,
): ComponentDescriptorMap {
  const memoizedComponentDescriptorMap = useMemo(() => {
    const rawComponentDescriptors = result.allComponentMetadata.nodes.filter(
      (n) => n.displayName in mdxComponents,
    )
    const componentDescriptors = (
      rawComponentDescriptors as any[]
    ).map<ComponentDescriptor>((raw) => ({
      name: raw.displayName,
      description: raw.description.text,
      props: (raw.props as any[]).map((rawProp) => ({
        name: rawProp.name,
        type: rawProp.type?.name,
        tsType: rawProp.tsType,
        defaultValue: rawProp.defaultValue?.value,
        description: rawProp.description.text,
        required: rawProp.required,
      })),
    }))

    return new Map<string, ComponentDescriptor>(
      componentDescriptors.map((d) => [d.name, d]),
    )
  }, [result, mdxComponents])

  return memoizedComponentDescriptorMap
}

export function useRegisterAutocomplete(monaco: typeof Monaco) {
  const componentDescriptorsResult =
    useStaticQuery<Queries.MonacoCodeCompletionComponentDescriptorsQuery>(
      COMPONENT_DESCRIPTOR_QUERY,
    )
  const componentDescriptorMap = useComponentDescriptorMap(
    componentDescriptorsResult,
  )
  const [registered, setRegistered] = useState(false)

  useEffect(() => {
    if (!monaco || !registered) {
      return
    }
    registerMdxComponentAutocomplete({
      monaco,
      components: componentDescriptorMap,
    })
    registerMdxComponentPropertyAutocomplete({
      monaco,
      components: componentDescriptorMap,
    })
    registerMdxComponentPropertyValueAutocomplete({
      monaco,
      components: componentDescriptorMap,
    })
  }, [monaco, componentDescriptorMap, registered])

  return () => setRegistered(true)
}

import { mediaQueries } from '@/styles/media-queries'
import React, { useMemo } from 'react'

import * as styles from './hidden.css'

interface HiddenProps {
  children: React.ReactElement
  /** Hide the wrapped elements from given breakpoint */
  from: keyof typeof mediaQueries
  /** Hide the wrapped elements till given breakpoint */
  till: keyof typeof mediaQueries
}

/**
 * Wrap elements with Hidden to hide them at certain viewport sizes.
 */
export const Hidden: React.FC<HiddenProps> = ({ children, from, till }) => {
  const modifiedChildren = useMemo(
    () => (
      <>
        {React.Children.map(children, (child, i) =>
          React.cloneElement(child, {
            className: [
              child.props.className,
              styles.fromVariant[from],
              styles.tillVariant[till],
            ].join(` `),
            // @todo is key really needed?
            // eslint-disable-next-line react/no-array-index-key
            key: `${i}`,
          }),
        )}
      </>
    ),
    [children, from, till],
  )

  return modifiedChildren
}

import "src/styles/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4WX21IiMRCG73mKlNzIlriTnvN4s4JStVd74e4DjDAIKgzOAOJu8e7bQV2S7majVR4++k8mIf1Fi6au1+pPR6l+v5481k+7oFBJsNpdWUgXSgfBdnbV2XcKFoBCBXZ1iL9fQtxUC5tGhlIY48AEJYhYXVooICgrVEhQXqiIoLJQdKx7XB9B40JlBE3MY2QpDVf8gaccPSDSkLmbOHvfxFebzQs1Lp/H54cv27I5/7fjPfUF9wu/9ZXNg17Pzj968uDJP3nyoSf/7MlHnvzCk489+aUnn3jytSefevIrTz7z5F88+dyTbz7yJPrfTIuNk12C6eYjXGNT4Hl34aZQ3WQwiG4HNt0ihWEe3YJNXw0dxTfX1zbdIdXhcDiMbPpmaoP8Onfob6TT6dTxDrqoG2Tm08Hoo+5NpgfByMEoou4oGMHIGVejj85+rKqluiuX7dmFOrurHupK/fqOP/8sZ/WivFAtvtRvq2buzo/SCgNHbhqVlRKEyooISnkw41U5HwuFReV7L/pUjw0OmaH0RBKtrgzlxYK89INkYD3jCtZz7mCNSsrxg6wBRaPVV6UdiPbQCdLcoeiECGHoQGx0U6ldWpsnTXT2PvK+c/lxAYFzP+mVvPYXYe2NXNqaiVjx2hTzO0JvTDV/w7bCfK/iXu8O0/ER3gxPKf0tV0MgVQP2DkjVYDijoUgj/r5DLLDDXR46ngDTGXyHIRNKzX3OJy+FSuFOh7FQN+EXPVRC3Yz1KmCnhJF7qgE7JUoIw56YL2dokrWDn1mrA3YKhCSNfRJqwmoexR4BWoYtAqTtoOHRlXSU4eWwCZRu+Da0/BDDWjrEsGMKBKEDQOwAMCed7rY552SBIZ7x2J0k1KYrg9QMuO98W1STeanacVPhBVAuJ+p8MV/2X+eTNb7HaYJ/o/UOsnDVcVIesj6oQDKpvnWs4GpEGGhj7wuxiNW/1CUp5webhGLk7Sh1KpREqDdKQXlIy6NeOWWWU26R7SL7RTbMScdQy9BqRzNUNLTYMg11DS21ZMN0Q+DRN65xoo//IVznCCtG6/Tx1OuYzXcUj6ueT5m58vnUmagfWUCugsKY0UYawGiILw57CjjdSLvTnuqDdx3xPrCEJCvplJSIlvjzYb8AW7alJllOSu07+789aJqFfw8AAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/mdx/layout/grid.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/mdx/layout/grid.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA43VUY6CMBSF4XdX0RcTSayhgIi4miqFQcViRdSZzN7HgCb3JG3HR+h3CQ0/sOjbsDc6ZD8Txor60h7lI2eVqYvN5HeyGFfFsFrJNme9NDPOdbHXh3sUEBPZTUxNbDcJNYndLKlZ2k1KTWo3K2pWdpNRk9nNmpq13UhqpN1sqdnazY6and0U1BR2o6hRdlNSUw5mp4/X5sT9j7/yUqjgy0shhtpLoYm9l0IaBy+FQo5eCqE0Xgq9nLwUstFeCvW0XgoRnb0UWjJeCkldvBTK6gZq9O2frK5uB031bgdB3dwOarq7HaT0cDvo6NvtICIRuiEkJIQbQkAickPIR8RuCPGIxA0hHbF0QwhHpG4I2Yjx68f56zDLWSpKw+KsNBu6IHNmVKtkNxuv9Tq9CuasqU+NvM/COXtOBgGMrd1j8tppxNucJdGUJdmU3uHr2/v8gfJONc/faaf4+Epc3ht73zzsTH08hx2UH89hFtXHc0Mlf8sfIHkoCAAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var __esModule = true;
export var gap = {none:'vp0vro1',xs:'vp0vro2',sm:'vp0vro3',md:'vp0vro4',lg:'vp0vro5',xl:'vp0vro6','2xl':'vp0vro7','3xl':'vp0vro8','4xl':'vp0vro9','5xl':'vp0vroa','6xl':'vp0vrob',gridGap:'vp0vroc',contentColumn:'vp0vrod',paragraphGap:'vp0vroe'};
export var gapX = {none:'vp0vrof',xs:'vp0vrog',sm:'vp0vroh',md:'vp0vroi',lg:'vp0vroj',xl:'vp0vrok','2xl':'vp0vrol','3xl':'vp0vrom','4xl':'vp0vron','5xl':'vp0vroo','6xl':'vp0vrop',gridGap:'vp0vroq',contentColumn:'vp0vror',paragraphGap:'vp0vros'};
export var gapY = {none:'vp0vrot',xs:'vp0vrou',sm:'vp0vrov',md:'vp0vrow',lg:'vp0vrox',xl:'vp0vroy','2xl':'vp0vroz','3xl':'vp0vro10','4xl':'vp0vro11','5xl':'vp0vro12','6xl':'vp0vro13',gridGap:'vp0vro14',contentColumn:'vp0vro15',paragraphGap:'vp0vro16'};
export var grid = 'vp0vro0';
export var gridColumnCountVar = 'var(--vp0vro17)';
export var gridTheme = 'vp0vro1c';
export var gridVars = {templates:{goldenRatio:'var(--vp0vro18)',dynamic:'var(--vp0vro19)',equal:'var(--vp0vro1a)',soniq:'var(--vp0vro1b)'}};
export var template = {goldenRatio:'vp0vro1d',dynamic:'vp0vro1e',equal:'vp0vro1f',soniq:'vp0vro1g'};
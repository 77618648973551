import {
  AdaptiveProperty,
  useAdaptiveProperty,
} from '@/hooks/use-adaptive-property'
import React from 'react'

import * as styles from './background-color.css'

interface BackgroundColorProps {
  /** Set the background color */
  color: AdaptiveProperty<keyof typeof styles.color>
}

/**
 * This will draw a background color to next container parent. Usually these are <Section/> or <Box/>
 */
export const BackgroundColor: React.FC<BackgroundColorProps> = ({
  color,
  ...props
}) => {
  const adaptiveColor = useAdaptiveProperty(color)
  return (
    <div
      className={[styles.backgroundColor, styles.color[adaptiveColor]].join(
        ` `,
      )}
      {...props}
    />
  )
}

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import type * as Monaco from 'monaco-editor'
import type * as LoaderJS from 'loader.js'
import { useLocalStorage } from 'react-use'

import Editor from './editor'

import * as styles from './interface.css'

declare global {
  interface Window {
    require: LoaderJS
    monaco?: typeof Monaco
  }
}

const EditorInterface = ({ monaco }) => {
  const defaultContent =
    useStaticQuery<Queries.editorDefaultContentQuery>(graphql`
      query editorDefaultContent {
        contentfulPage(slug: { eq: "index" }) {
          mdx {
            childMdx {
              rawBody
            }
          }
        }
      }
    `)

  const [value, setValue] = useLocalStorage(
    `mdx-editor`,
    defaultContent.contentfulPage.mdx.childMdx?.rawBody,
    { raw: true },
  )

  return (
    <div className={styles.wrapper}>
      <iframe
        className={styles.preview}
        src="/___/preview"
        title="Content preview"
      />
      <Editor monaco={monaco} value={value} setValue={setValue} />
    </div>
  )
}

export default EditorInterface

import React, { useCallback, useMemo, useState } from 'react'
import { Button } from 'reakit/Button'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'

import { Icon } from '@/components/icon'
import { Image } from '@/components/image'
import { LinkProps } from '@/components/link'
import { BackgroundTriangle } from '@/components/mdx/backgrounds/background-triangle'

import {
  blockFontRecipe,
  BlockFontRecipe,
  inlineFontRecipe,
  InlineFontRecipe,
} from '@/styles/recipies/font.css'

import * as styles from './slideshow.css'

interface SlideshowSlideProps
  extends InlineFontRecipe,
    BlockFontRecipe,
    styles.SlideRecipe {
  /** Set the hover label for the next/previous button */
  buttonLabel: string
  /** The id of the contentful image to be rendered next to the content */
  imageId: string
  /** Links to render below the content. Usually <Link kind="button" .../> */
  links?: React.ReactElement[]
  scrollTo?: (i) => void
  slideId?: number
  slideCount?: number
  children: React.ReactNode
}

export const SlideshowSlide: React.FC<SlideshowSlideProps> = ({
  children,
  colorScheme,
  scrollTo,
  slideId,
  slideCount,
  textAlign,
  family,
  color = `white`,
  size,
  weight,
  decoration,
  transform,
  imageId,
  links,
  buttonLabel,
}) => {
  const controls = useMemo(
    () =>
      React.Children.map(links, (child: React.ReactElement) => {
        if (!React.isValidElement<LinkProps>(child)) {
          return null
        }
        return React.cloneElement(child, {
          className: [styles.slideControl, child.props.className].join(` `),
        })
      }),
    [links],
  )
  const prevSlide = useCallback(() => {
    scrollTo(slideId - 1)
  }, [scrollTo, slideId])
  const nextSlide = useCallback(() => {
    scrollTo(slideId + 1)
  }, [scrollTo, slideId])

  return (
    <section className={[styles.slideRecipe({ colorScheme })].join(` `)}>
      <BackgroundTriangle
        direction={slideId % 2 ? `left` : `right`}
        color={colorScheme}
        modifierX="5%"
        modifierY="20%"
      />
      <div className={styles.slideContentWrapper}>
        {slideId > 0 && (
          <Button
            onClick={prevSlide}
            className={[styles.arrow, styles.arrowLeft].join(` `)}
            title={buttonLabel}
            aria-label={buttonLabel}
          >
            <Icon
              name="arrow"
              style={{
                transform: `rotate(180deg)`,
              }}
            />
          </Button>
        )}
        <div className={styles.slideMedia}>
          <Image
            id={imageId}
            preset="half"
            className={styles.slideImage}
            fit="contain"
          />
        </div>
        <div
          className={[
            styles.slideContent,
            blockFontRecipe({ textAlign }),
            inlineFontRecipe({
              family,
              color,
              size,
              weight,
              decoration,
              transform,
            }),
          ].join(` `)}
        >
          {children}
        </div>
        <div className={styles.slideControls}>{controls}</div>
        {slideId < slideCount - 1 && (
          <Button
            onClick={nextSlide}
            className={[styles.arrow, styles.arrowRight].join(` `)}
            title={buttonLabel}
            aria-label={buttonLabel}
          >
            <Icon name="arrow" />
          </Button>
        )}
      </div>
    </section>
  )
}

interface SlideShowProps {
  children: React.ReactElement[]
}

/** Renders a slideshow of all children. Should be Section or Box elements. Could work with others. */
export const Slideshow: React.FC<SlideShowProps> = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState(0)
  const handleOnChange = useCallback(
    (index: number, _item: React.ReactNode) => {
      setSelectedItem(index)
    },
    [],
  )
  const scrollTo = useCallback(
    (slideId) => setSelectedItem(slideId),
    [setSelectedItem],
  )
  const slides = useMemo(
    () =>
      React.Children.map(children, (child: React.ReactElement, i) => {
        if (!React.isValidElement<SlideshowSlideProps>(child)) {
          return null
        }
        return React.cloneElement(child, {
          scrollTo,
          slideId: i,
          slideCount: children.length,
        })
      }),
    [children],
  )

  return (
    <div className={styles.wrapper}>
      <ResponsiveCarousel
        selectedItem={selectedItem}
        onChange={handleOnChange}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        preventMovementUntilSwipeScrollTolerance
        swipeable
        emulateTouch
      >
        {slides}
      </ResponsiveCarousel>
    </div>
  )
}

import React from 'react'

import * as styles from './facts.css'

interface FactsProps {
  children: React.ReactNode
}

/** Shows multiple Fact elements next to each other. */
export const Facts: React.FC<FactsProps> = (props) => (
  <div className={styles.facts} {...props} />
)

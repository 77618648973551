import React, { useCallback, useEffect, useState } from 'react'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import { useWindowSize } from 'react-use'
import { Trans } from '@lingui/macro'

import { useBreakpoints } from '@/hooks/use-breakpoints'
import { Image } from '@/components/image'
import { Link } from '@/components/link'

import * as styles from './carousel.css'
import './react-responsive-carousel.css'

interface CarouselPaneSuccessStoryProps {
  imageId: string
  quote: string
  pageId: string
  name: string
  jobTitle: string
  company: string
}

export const CarouselPaneSuccessStory: React.FC<
  CarouselPaneSuccessStoryProps
> = ({ imageId, quote, pageId, name, jobTitle, company }) => (
  <div className={styles.slide}>
    <div className={styles.box}>
      <Image className={styles.image} id={imageId} preset="quarter" />
      <div className={styles.quote}>‘{quote}’</div>
      {pageId ? (
        <Link className={styles.link} id={pageId}>
          <Trans>Read more</Trans>
        </Link>
      ) : (
        <div className={styles.link} style={{ visibility: `hidden` }}>
          Do not read more
        </div>
      )}
      <div>
        <div className={styles.name}>{name}</div>
        <div className={styles.jobTitle}>{jobTitle}</div>
        <div className={styles.company}>{company}</div>
      </div>
    </div>
  </div>
)

interface CarouselProps {
  children: React.ReactChild[]
}

export const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const { width } = useWindowSize()

  const [selectedItem, setSelectedItem] = useState(0)
  const handleOnChange = useCallback(
    (index: number, _item: React.ReactNode) => {
      setSelectedItem(index)
    },
    [],
  )

  const { desktop } = useBreakpoints()

  // Force rerender on screen resize
  useEffect(() => {
    setSelectedItem(selectedItem > 0 ? 0 : 1)
  }, [width])

  return (
    <ResponsiveCarousel
      centerMode={!!desktop}
      infiniteLoop
      centerSlidePercentage={33}
      selectedItem={selectedItem}
      onChange={handleOnChange}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      className={!desktop && `with-dots`}
      preventMovementUntilSwipeScrollTolerance
      swipeable={!desktop}
      emulateTouch={!desktop}
    >
      {children}
    </ResponsiveCarousel>
  )
}
